/**
 * MercureClient.js
 * Clase para suscribirse a actualizaciones de un servidor Mercure.
 *
 * @category   Frontend
 * @package    MercureClient
 * @author     Wilson Pérez @wilodev <wperez@cintanegra.net>
 * @license    http://www.infinyti.tech/license/1.0.txt  Infinyti License 1.0
 * @link       http://www.infinyti.tech
 *
 * @example
 * Crear una nueva instancia y suscribirse a un tópico específico
 * const mercureClient = new MercureClient('https://tu-servidor-mercure');
 * mercureClient.subscribe('my-topic');
 */

class MercureClient {
	/**
	 * Constructor de MercureClient.
	 * @param {string} [url='https://default-mercure-server.com'] - URL del servidor Mercure.
	 * @param {string} jwtToken - Token JWT para la autenticación.
	 */
	constructor(
		url = "https://138.197.72.181:3000/.well-known/mercure",
		jwtToken
	) {
		this.url = url;
		this.jwtToken = jwtToken;
		this.eventSources = {};
	}

	/**
	 * Suscribe a un tópico específico en el servidor Mercure.
	 *
	 * @param {string} topic - El tópico al que suscribirse.
	 * @param {Function} callback - Función a ejecutar cuando se reciban datos.
	 */
	subscribe(topic, callback) {
		const url = new URL(this.url);
		url.searchParams.append("topic", topic);

		const eventSource = new EventSource(url, {
			headers: {
				Authorization: "Bearer " + this.jwtToken,
			},
		});
		eventSource.onmessage = ({ data }) => {
			callback(JSON.parse(data));
		};

		this.eventSources[topic] = eventSource;
	}

	/**
	 * Cancela la suscripción a un tópico específico.
	 *
	 * @param {string} topic - El tópico al que cancelar la suscripción.
	 */
	unsubscribe(topic) {
		if (this.eventSources[topic]) {
			this.eventSources[topic].close();
			delete this.eventSources[topic];
		}
	}

	/**
	 * Cancela todas las suscripciones activas.
	 */
	unsubscribeAll() {
		for (let topic in this.eventSources) {
			this.unsubscribe(topic);
		}
	}
}

// Exportar la clase para que pueda ser utilizada en diferentes módulos.
export default MercureClient;
