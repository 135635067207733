/**
 * TODO: crear documentacion de DataLoader
 *
 * Componente para agregar y eliminar instancias de un template para manejo de
 * relaciones muchos a muchos en formularios.
 *
 * @param {Object} $options - Opciones para configurar el componente
 *
 * @example
 * Inicializa el componente con las opciones predeterminadas
 * new ManyToManySelector();
 *
 * @example
 * Inicializa el componente con opciones personalizadas
 * new ManyToManySelector({
 *    orderField: 'order',
 *    optionTemplate: '#race-option',
 * });
 *
 * ES6 Module
 *
 * @category   Nucleus
 * @package    Mixins
 * @author     Miguel Valencia @mavo <nova_mig@hotmail.com>
 */

class DataLoader {
    constructor() {
        this.fieldLoaders = {
            'text': this.loadTextField,
            'password': this.loadTextField,
            'email': this.loadTextField,
            'number': this.loadTextField,
            'date': this.loadTextField,
            'time': this.loadTextField,
            'textarea': this.loadTextArea,
            'checkbox': this.loadCheckbox,
            'radio': this.loadRadio,
            'select': this.loadSelect,
            'file': this.loadFile,
            'submit': this.loadSubmit,
            'button': this.loadButton,
        };
    }

    loadField(fieldElement, fieldValue) {
        let fieldType = fieldElement.type;

        switch (fieldType) {
            case 'text':
            case 'password':
            case 'email':
            case 'number':
            case 'date':
            case 'time':
                this.loadTextField(fieldElement, fieldValue);
                break;

            case 'textarea':
                this.loadTextArea(fieldElement, fieldValue);
                break;

            case 'checkbox':
                this.loadCheckbox(fieldElement, fieldValue);
                break;

            case 'radio':
                this.loadRadio(fieldElement, fieldValue);
                break;

            case 'select-one':
            case 'select-multiple':
                this.loadSelect(fieldElement, fieldValue);
                break;

            case 'file':
                this.loadFile(fieldElement, fieldValue);
                break;

            case 'submit':
                this.loadSubmit(fieldElement, fieldValue);
                break;

            case 'button':
                this.loadButton(fieldElement, fieldValue);
                break;

            default:
                console.error(`Tipo de campo no compatible: ${fieldType}`);
                break;
        }
    }

    loadTextField(value, element) {
        if (element && element.tagName === 'INPUT' && (element.type === 'text' || element.type === 'password' || element.type === 'email' || element.type === 'number' || element.type === 'date' || element.type === 'time')) {
            element.value = value;
        }
    }

    loadTextArea(value, element) {
        if (element && element.tagName === 'TEXTAREA') {
            element.value = value;
        }
    }

    loadCheckbox(value, element) {
        if (element && element.tagName === 'INPUT' && element.type === 'checkbox') {
            element.checked = value === 'true';
        }
    }

    loadRadio(value, element) {
        if (element && element.tagName === 'INPUT' && element.type === 'radio') {
            element.checked = element.value === value;
        }
    }

    loadSelect(value, element) {
        if (element && element.tagName === 'SELECT') {
            for (let i = 0; i < element.options.length; i++) {
                if (element.options[i].value === value) {
                    element.selectedIndex = i;
                    break;
                }
            }
        }
    }

    loadFile(value, element) {
        if (element && element.tagName === 'INPUT' && element.type === 'file') {
            // Puedes implementar la carga de archivos según tus necesidades
        }
    }

    loadSubmit(value, element) {
        if (element && element.tagName === 'INPUT' && element.type === 'submit') {
            // Puedes implementar la carga de botones de envío de formulario según tus necesidades
        }
    }

    loadButton(value, element) {
        if (element && element.tagName === 'INPUT' && element.type === 'button') {
            // Puedes implementar la carga de botones genéricos según tus necesidades
        }
    }
}

module.exports = DataLoader;