/**
 * @fileoverview This class handles HTTP requests using Fetch API.
 * It supports optional base URL and API token, with fallback to environment variables.
 * It also scans the DOM for elements with a specific data attribute to automatically send requests.
 *
 * @author Wilson Fabian Pérez Sucuzhañay
 * @contact wilsonperez.developer@gmail.com, wperez@cintanegra.net
 * @copyright 2024 @wilodev
 *
 * @example
 * // Creating an instance of the client
 * const client = new NucleusFetchClient('https://api.example.com', 'your-api-token');
 *
 * // Making a GET request
 * client.get('/endpoint')
 *   .then(response => console.log(response))
 *   .catch(error => console.error(error));
 *
 * // Making a POST request with data and Bearer Token
 * client.post('/endpoint', { key: 'value' }, true)
 *   .then(response => console.log(response))
 *   .catch(error => console.error(error));
 *
 * // Examples of using the client in HTML
 * // Input
 * <input
 *   type="text"
 *   data-nc-fetch="/api/search"
 *   data-nc-fetch-actions="search"
 *   data-nc-fetch-method="GET"
 *   data-nc-response-target="#result"
 *   data-nc-response-path="data.results"
 *   data-nc-use-query-params="true"
 *   data-nc-callback="processResults"
 * />
 *
 * // Select
 * <select
 *   data-nc-fetch="/api/options"
 *   data-nc-fetch-actions="change"
 *   data-nc-fetch-method="GET"
 *   data-nc-response-target="#options-result"
 *   data-nc-response-path="data.options"
 * >
 *   <option value="1">Option 1</option>
 *   <option value="2">Option 2</option>
 * </select>
 *
 * // Button
 * <button
 *   data-nc-fetch="/api/submit"
 *   data-nc-fetch-actions="click"
 *   data-nc-fetch-method="POST"
 *   data-nc-fetch-payload='{"key": "value"}'
 *   data-nc-response-target="#submit-result"
 *   data-nc-response-path="data.message"
 *   data-nc-payload-type="json" or data-nc-payload-type="form"
 * >
 *   Submit
 * </button>
 *
 * // P
 * <p
 *   data-nc-fetch="/api/info"
 *   data-nc-fetch-actions="mouseenter,mouseleave"
 *   data-nc-fetch-method="GET"
 *   data-nc-response-target="#info-result"
 *   data-nc-response-path="data.info"
 * >
 *   Hover over me to fetch info
 * </p>
 *
 * // Div
 * <div
 *   data-nc-fetch="/api/data"
 *   data-nc-fetch-actions="click"
 *   data-nc-fetch-method="GET"
 *   data-nc-response-target="#data-result"
 *   data-nc-response-path="data.content"
 * >
 *   Click me to fetch data
 * </div>
 *
 * // Container
 * <div
 *   data-nc-fetch-container
 *   data-nc-fetch="/api/endpoint"
 *   data-nc-fetch-method="POST"
 *   data-nc-payload-type="json" or data-nc-payload-type="form"
 *   data-nc-fetch-trigger="[data-nc-trigger]"
 *   data-nc-fetch-actions="blur"
 *   data-nc-fetch-include="[data-nc-include]"
 * >
 *      <select name="country" data-nc-include>
 *           <option value="us">USA</option>
 *           <option value="ca">Canada</option>
 *       </select>
 *   <input type="hidden" name="state" data-nc-include>
 *   <input type="text" name="city" data-nc-trigger>
 *   </div>
 *
 * // Multiple Container
 * <div
 *   data-nc-fetch-container
 *   data-nc-fetch="/api/endpoint/one"
 *   data-nc-fetch-method="POST"
 *   data-nc-payload-type="json" or data-nc-payload-type="form"
 *   data-nc-fetch-trigger="[data-nc-trigger]"
 *   data-nc-fetch-actions="click"
 *   data-nc-fetch-include="[data-nc-include]"
 *   data-nc-response-path
 * >
 *      <div
 *          data-nc-fetch-container
 *          data-nc-fetch="/api/endpoint/two"
 *          data-nc-fetch-method="POST"
 *          data-nc-payload-type="json" or data-nc-payload-type="form"
 *          data-nc-fetch-trigger="[data-nc-trigger-two]"
 *          data-nc-fetch-actions="onchange"
 *          data-nc-fetch-include="[data-nc-include-two]"
 *          data-nc-response-path
 *      >
 *          <select name="country" data-nc-include-two data-nc-trigger-two>
 *               <option value="us">USA</option>
 *               <option value="ca">Canada</option>
 *          </select>
 *          <div id="result"></div>
 *      </div>
 *      <input type="hidden" name="state" data-nc-include>
 *      <input type="text" name="city" data-nc-include>
 *      <input type="hidden" name="state" data-nc-include>
 *      <input type="text" name="city">
 *      <button type="button" name="send" data-nc-trigger>Send</button>
 *   </div>
 *
 * <div id="result"></div>
 *
 */

class NucleusFetchClient {
    /**
     * Creates an instance of NucleusFetchClient.
     *
     * @constructor
     * @param {string} [baseURL] - The base URL for the API.
     * @param {string} [apiToken] - The API token for authentication.
     */
    constructor(baseURL, apiToken) {
        this.baseURL = baseURL || process.env.BASE_URL || '';
        this.apiToken = apiToken || process.env.API_TOKEN || '';

        this.initDOMFetch();
    }

    /**
     * Initializes the DOM fetch mechanism.
     * Scans the DOM for elements with the data-fetch attribute and sets up event listeners.
     *
     * @private
     */
    initDOMFetch() {
        const elements = document.querySelectorAll('[data-nc-fetch]:not([data-nc-fetch-container])');
        elements.forEach(element => {
            const actions = element.dataset.ncFetchActions ? element.dataset.ncFetchActions.split(',') : [];
            if (actions.length > 0) {
                actions.forEach(action => this.addEventListener(element, action));
            } else {
                const action = element.dataset.ncFetchActions
                this.addEventListener(element, action);
            }

        });
        const containers = document.querySelectorAll('[data-nc-fetch-container]');
        containers.forEach(container => {
            this.addContainerEventListener(container);
        });
    }

    /**
     * Adds event listener based on action type.
     *
     * @private
     * @param {HTMLElement} element - The element to add event listener to.
     * @param {string} action - The action type (e.g., click, focus, blur, etc.).
     */
    addEventListener(element, action) {
        switch (action) {
            case 'click':
                element.addEventListener('click', this.handleFetch.bind(this));
                break;
            case 'focus':
                element.addEventListener('focus', this.handleFetch.bind(this));
                break;
            case 'blur':
                element.addEventListener('blur', this.handleFetch.bind(this));
                break;
            case 'mouseenter':
                element.addEventListener('mouseenter', this.handleFetch.bind(this));
                break;
            case 'mouseleave':
                element.addEventListener('mouseleave', this.handleFetch.bind(this));
                break;
            case 'change':
                element.addEventListener('change', this.handleFetch.bind(this));
                break;
            case 'search':
                this.addSearchEventListener(element);
                break;
            default:
                console.warn(`Unsupported action: ${action}`);
        }
    }


    /**
     * Adds event listener for search with delay after user stops typing.
     *
     * @private
     * @param {HTMLElement} element - The search element to add event listener to.
     */
    addSearchEventListener(element) {
        let timeout;
        element.addEventListener('input', () => {
            if (element.dataset?.ncResponseTarget) {
                // Elimina el element con ncResponseTarget todo su contenido
                document.getElementById(element.dataset?.ncResponseTarget.replace('#', '')).innerHTML = '';
            }
            clearTimeout(timeout);
            const query = element.value.trim();

            timeout = setTimeout(() => {
                if (query.length > 2) {
                    this.handleFetch({ currentTarget: element });
                }
            }, 500); // Delay of 500ms

        });
    }

    /**
     * Adds event listeners to container elements.
     *
     * @private
     * @param {HTMLElement} container - The container element.
     */
    addContainerEventListener(container) {
        const triggerSelector = container.dataset.ncFetchTrigger;
        const triggerElement = container.querySelector(triggerSelector);

        if (triggerElement) {
            const actions = container.dataset.ncFetchActions ? container.dataset.ncFetchActions.split(',') : [];
            actions.forEach(action => this.addEventListener(triggerElement, action));
        } else {
            console.warn(`Trigger element not found for selector: ${triggerSelector}`);
        }

        // Add event listeners to nested containers
        const nestedContainers = container.querySelectorAll('[data-nc-fetch-container]');
        nestedContainers.forEach(nestedContainer => {
            this.addContainerEventListener(nestedContainer);
        });
    }

    /**
     * Handles the fetch request triggered by a DOM element.
     *
     * @private
     * @param {Event} event - The event object.
     */
    async handleFetch(event) {
        const element = event.currentTarget;
        const isContainer = element.dataset.ncFetchContainer;
        const container = !isContainer ? element : element.closest('[data-nc-fetch-container]') || element;
        this.setLoadingState(isContainer ? null : element, isContainer ? container : null, true);

        const endpoint = element.dataset.ncFetch || container.dataset.ncFetch || '';
        const method = element.dataset.ncFetchMethod || container.dataset.ncFetchMethod || 'GET';
        const action = element.dataset.ncFetchActions || container.dataset.ncFetchActions || '';
        const baseURL = element.dataset.ncBaseUrl || container.dataset.ncBaseUrl || this.baseURL;
        const apiToken = element.dataset.ncApiToken || container.dataset.ncApiToken || this.getTokenFromSource(element.dataset.ncFetchBearerTokenSource || container.dataset.ncFetchBearerTokenSource) || this.apiToken;
        const useBearerToken = (element.dataset.ncUseBearerToken || container.dataset.ncUseBearerToken || 'false') === 'true';
        const payloadType = element.dataset.ncPayloadType || container.dataset.ncPayloadType || 'json';
        const useQueryParams = (element.dataset.ncUseQueryParams === 'true' || container.dataset.ncUseQueryParams === 'true' || method === 'GET');
        const payload = this.buildPayload(container, element, method, action, isContainer) || {};
        const extraPayloadString = element.dataset.ncFetchExtraPayload || container.dataset.ncFetchExtraPayload || '{}';

        let extraPayload = {};
        try {
            extraPayload = JSON.parse(extraPayloadString);
        } catch (error) {
            console.error("Error parsing extra payload JSON:", error);
        }
        const combinedPayload = { ...payload, ...extraPayload };
        const responseTarget = element.dataset.ncResponseTarget || container.dataset.ncResponseTarget || '';
        const responsePath = element.dataset.ncResponsePath || container.dataset.ncResponsePath || '';
        const errorResponseTarget = element.dataset.ncErrorResponseTarget || container.dataset.ncErrorResponseTarget || responseTarget;
        const errorResponsePath = element.dataset.ncErrorResponsePath || container.dataset.ncErrorResponsePath || '';
        const callbackFunction = element.dataset.ncCallback;

        const headers = this.constructHeaders(useBearerToken, apiToken);

        try {
            const response = await this.makeRequest(baseURL, endpoint, method, headers, combinedPayload, payloadType, useQueryParams);
            console.log("🚀 ~ file: FetchClient.js:307 ~ NucleusFetchClient ~ handleFetch ~ response:", response)
            if (responseTarget && responsePath) {
                if (response.status === "error") {
                    this.handleResponse(response, errorResponseTarget, errorResponsePath, container, true, callbackFunction);
                } else {
                    this.handleResponse(response, responseTarget, responsePath, container, false, callbackFunction);
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
            if (errorResponseTarget && errorResponsePath) {
                this.handleResponse(error, errorResponseTarget, errorResponsePath, container, true, callbackFunction);
            } else if (responseTarget && errorResponsePath) {
                this.handleResponse(error, responseTarget, errorResponsePath, container, true, callbackFunction);
            }
        } finally {
            if (!isContainer) {
                // Solo elimina el estado de carga del elemento específico si no es un contenedor
                this.setLoadingState(element, null, false);
            } else {
                // Elimina el estado de carga del contenedor completo
                this.setLoadingState(null, container, false);
            }
        }
    }

    /**
     * Constructs the payload for the request.
     *
     * @private
     * @param {HTMLElement} container - The container element.
     * @param {HTMLElement} triggerElement - The element that triggered the fetch.
     * @returns {Object} The payload object.
     */
    buildPayload(container, triggerElement, method, action, isContainer) {
        let payload = {};
        const includeSelector = container.dataset.ncFetchInclude || '';
        const excludeSelector = container.dataset.ncFetchExclude || '';

        if (isContainer) {
            if (includeSelector) {
                const includeElements = container.querySelectorAll(includeSelector);
                includeElements.forEach(element => {
                    payload[element.name] = element.value;
                });
            } else {
                const allElements = container.querySelectorAll('input, select, textarea');
                allElements.forEach(element => {
                    if (!excludeSelector || !element.matches(excludeSelector)) {
                        payload[element.name] = element.value;
                    }
                });
            }
        } else if (triggerElement) {
            if (action === 'search' && method === 'GET' && !isContainer) {
                payload['search'] = triggerElement.value;
            } else {
                payload[triggerElement.name] = triggerElement.value;
            }
        }

        return payload;
    }

    /**
     * Constructs the headers for the request.
     *
     * @private
     * @param {boolean} useBearerToken - Whether to use Bearer Token for authentication.
     * @param {string} apiToken - The API token for authentication.
     * @returns {Object} The headers object.
     */
    constructHeaders(useBearerToken, apiToken) {
        const headers = {};
        if (useBearerToken && apiToken) {
            headers['Authorization'] = `Bearer ${apiToken}`;
        } else if (apiToken) {
            headers['X-API-Token'] = apiToken;
        }
        return headers;
    }

    /**
     * Makes an HTTP request based on the specified method.
     *
     * @private
     * @param {string} baseURL - The base URL for the API.
     * @param {string} endpoint - The endpoint to fetch data from.
     * @param {string} method - The HTTP method (GET, POST, PUT, PATCH, DELETE).
     * @param {Object} headers - The headers for the request.
     * @param {Object} [payload] - The payload for the request.
     * @param {string} payloadType - The type of payload ('json' or 'form').
     * @param {boolean} [useQueryParams=false] - Whether to use query parameters in the URL.
     * @returns {Promise<any>} The response data.
     */
    async makeRequest(baseURL = "", endpoint, method, headers, payload = null, payloadType = 'json', useQueryParams = false) {
        let url = `${baseURL}${endpoint}`;
        const options = {
            method,
            headers,
        };

        if (useQueryParams || method === 'GET') {
            const queryParams = new URLSearchParams(payload).toString();
            url += `?${queryParams}`;
        } else if (['POST', 'PUT', 'PATCH'].includes(method) && payload) {
            if (payloadType === 'json') {
                options.body = JSON.stringify(payload);
            } else if (payloadType === 'form') {
                const formData = new FormData();
                for (const key in payload) {
                    if (payload.hasOwnProperty(key)) {
                        formData.append(key, payload[key]);
                    }
                }
                options.body = formData;
            }
        }
        const response = await fetch(url, options);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
    }

    /**
     * Handles the response and inserts it into the specified DOM element.
     *
     * @private
     * @param {Object} response - The response data.
     * @param {string} targetSelector - The CSS selector of the target element.
     * @param {string} responsePath - The path to the desired response data.
     * @param {boolean} [isError=false] - Whether the response is an error.
     */
    handleResponse(response, targetSelector, responsePath, parent, isError = false, callbackFunction = null) {
        const targetElement = document.querySelector(targetSelector);
        if (!targetElement) {
            console.error(`Target element not found: ${targetSelector}`);
            return;
        }

        const data = this.extractResponseData(response, responsePath);
        if (data !== undefined) {
            if (callbackFunction && typeof window[callbackFunction] === 'function') {
                window[callbackFunction](data, targetElement, parent);
            } else {
                targetElement.innerHTML = isError ? `<span class="error">${data}</span>` : data;
            }
        } else {
            console.error(`Invalid response path: ${responsePath}`);
        }
    }


    /**
     * Extracts the desired data from the response using the response path.
     *
     * @private
     * @param {Object} response - The response data.
     * @param {string} path - The path to the desired response data.
     * @returns {any} The extracted data.
     */
    extractResponseData(response, path) {
        return path.split('.').reduce((acc, part) => acc && acc[part], response);
    }

    /**
     * Gets the token from localStorage.
     *
     * @private
     * @returns {string|null} The token from localStorage.
     */
    getTokenFromStorage() {
        return localStorage.getItem('token');
    }

    /**
     * Gets the token from cookies.
     *
     * @private
     * @returns {string|null} The token from cookies.
     */
    getTokenFromCookie() {
        const name = 'token=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    /**
     * Gets the token from the specified source.
     *
     * @private
     * @param {string} source - The source to get the token from ('localStorage' or 'cookie').
     * @returns {string|null} The token.
     */
    getTokenFromSource(source) {
        switch (source) {
            case 'localStorage':
                return this.getTokenFromStorage();
            case 'cookie':
                return this.getTokenFromCookie();
            default:
                return null;
        }
    }

    /**
     * Makes a GET request to the specified endpoint.
     *
     * @param {string} endpoint - The endpoint to fetch data from.
     * @param {boolean} [useBearerToken=false] - Whether to use Bearer Token for authentication.
     * @returns {Promise<any>} The response data.
     */
    async get(endpoint, useBearerToken = false) {
        return this.makeRequest(this.baseURL, endpoint, 'GET', this.constructHeaders(useBearerToken, this.apiToken));
    }

    /**
     * Makes a POST request to the specified endpoint.
     *
     * @param {string} endpoint - The endpoint to send data to.
     * @param {Object} data - The data to send in the body of the request.
     * @param {boolean} [useBearerToken=false] - Whether to use Bearer Token for authentication.
     * @returns {Promise<any>} The response data.
     */
    async post(endpoint, data, useBearerToken = false) {
        return this.makeRequest(this.baseURL, endpoint, 'POST', this.constructHeaders(useBearerToken, this.apiToken), data);
    }

    /**
     * Makes a PUT request to the specified endpoint.
     *
     * @param {string} endpoint - The endpoint to send data to.
     * @param {Object} data - The data to send in the body of the request.
     * @param {boolean} [useBearerToken=false] - Whether to use Bearer Token for authentication.
     * @returns {Promise<any>} The response data.
     */
    async put(endpoint, data, useBearerToken = false) {
        return this.makeRequest(this.baseURL, endpoint, 'PUT', this.constructHeaders(useBearerToken, this.apiToken), data);
    }

    /**
     * Makes a PATCH request to the specified endpoint.
     *
     * @param {string} endpoint - The endpoint to send data to.
     * @param {Object} data - The data to send in the body of the request.
     * @param {boolean} [useBearerToken=false] - Whether to use Bearer Token for authentication.
     * @returns {Promise<any>} The response data.
     */
    async patch(endpoint, data, useBearerToken = false) {
        return this.makeRequest(this.baseURL, endpoint, 'PATCH', this.constructHeaders(useBearerToken, this.apiToken), data);
    }

    /**
     * Makes a DELETE request to the specified endpoint.
     *
     * @param {string} endpoint - The endpoint to delete data from.
     * @param {boolean} [useBearerToken=false] - Whether to use Bearer Token for authentication.
     * @returns {Promise<any>} The response data.
     */
    async delete(endpoint, useBearerToken = false) {
        return this.makeRequest(this.baseURL, endpoint, 'DELETE', this.constructHeaders(useBearerToken, this.apiToken));
    }

    /**
     * Makes a custom request.
     *
     * @param {string} method - The HTTP method (GET, POST, PUT, PATCH, DELETE).
     * @param {string} endpoint - The endpoint to send the request to.
     * @param {Object} [data] - The data to send in the body of the request (for POST, PUT, PATCH).
     * @param {Object} [headers] - Additional headers to include in the request.
     * @returns {Promise<any>} The response data.
     */
    async request(method, endpoint, data = null, headers = {}) {
        const finalHeaders = {
            ...this.constructHeaders(false, this.apiToken),
            ...headers,
        };
        return this.makeRequest(this.baseURL, endpoint, method, finalHeaders, data);
    }

    /**
     * Sets the loading state of the specified element and its child input elements.
     *
     * This method adds or removes the `is-loading` class to/from the specified element and all
     * its descendant `input`, `select`, `textarea`, and `button` elements. The `is-loading` class
     * typically indicates that an element is in a loading state, such as being disabled or showing
     * a loading animation.
     *
     * @param {HTMLElement} element - The element to set the loading state for.
     * @param {boolean} isLoading - A boolean indicating whether to set or remove the loading state.
     *                              If true, the `is-loading` class is added; if false, it is removed.
     *
     * @example
     * // Set the loading state for a form element
     * const formElement = document.querySelector('form');
     * setLoadingState(formElement, true); // Adds the 'is-loading' class
     * setLoadingState(formElement, false); // Removes the 'is-loading' class
     *
     * @example
     * // Set the loading state for a container element with multiple inputs
     * const containerElement = document.querySelector('.input-container');
     * setLoadingState(containerElement, true); // Adds the 'is-loading' class to all inputs, selects, textAreas, and buttons
     * setLoadingState(containerElement, false); // Removes the 'is-loading' class from all inputs, selects, textAreas, and buttons
     */
    setLoadingState(element, container, isLoading) {
        if (isLoading) {
            element && element?.parentNode.classList.add('is-loading');
            container && container.classList.add('is-loading');
        } else {
            element && element?.parentNode.classList.remove('is-loading');
            container && container.classList.remove('is-loading');
        }
    }

}

export default NucleusFetchClient;

// const client = new NucleusFetchClient();
// const response = await client.request("GET", '/')
