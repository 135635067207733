/**
 * Componente para agregar y eliminar instancias de un template para manejo de
 * relaciones muchos a muchos en formularios.
 *
 * @param {Object} $options - Opciones para configurar el componente
 *
 * @example
 * Inicializa el componente con las opciones predeterminadas
 * new ManyToManySelector();
 *
 * @example
 * Inicializa el componente con opciones personalizadas
 * new ManyToManySelector({
 *    orderField: 'order',
 *    optionTemplate: '#race-option',
 * });
 *
 * ES6 Module
 *
 * @category   Nucleus
 * @package    Mixins
 * @author     Miguel Valencia @mavo <nova_mig@hotmail.com>
 */

import DataLoader from '../../nucleus/General/DataLoader';

const changeCase = require('change-case');              // Importa la biblioteca change-case
const nuc = require('../helpers/helpers');       // Importa todas las funciones helpers
const $_generalMixins = require('../mixins/generalMixins');  // Importa el mixin general

class ManyToManySelector {
    // Constructor de la clase ManyToManySelector, recibe $id del elemento y $options como parámetros opcionales
    constructor($id = null, $options = {}) {
        this.dataloader = new DataLoader();
        // Configuración predeterminada con las opciones proporcionadas
        // Object.assign() combina los objetos, sobrescribiendo propiedades coincidentes
        this.config = Object.assign({
            orderField: 'order',
            name: 'mtm',
            // optionTemplate: '#race-tpl',
        }, $options);

        // Inicializa un arreglo para almacenar los identificadores de las instancias
        this.instanceIds = [];

        // Llama al método init() para inicializar el componente        
        this.init($id);
    }

    // Método para inicializar el componente, busca elementos con el atributo data-nc-trigger='mtm-selector'
    init($id) {
        if ($id) {
            // Busca el elemento con el ID proporcionado
            const $mtmElement = document.querySelector($id);
            // Inicializa el componente ManyToManySelector
            this.initComponent($mtmElement);
        } else {
            // Busca todos los elementos con data-nc-trigger='mtm-selector'
            const $mtmElements = document.querySelectorAll('[data-nc-trigger="mtm-selector"]');
            // Para cada elemento encontrado, llama al método initComponent() para configurar el botón de agregar
            $mtmElements.forEach(($element) => this.initComponent($element));
        }
    }

    // Método para inicializar un componente individual
    initComponent($element) {
        // Busca el contenedor de instancias
        let $prevOptions = this.getConfigParam($element, 'rows');

        if ($prevOptions != null) {
            // Busca en el componente datos para cargar previamente instancias
            this.loadInstances($element, $prevOptions);
        }

        // Busca el botón con el atributo data-mtm-action='add-option'
        const $addButton = $element.querySelector('[data-nc-mtm-action="add-option"]');

        // Si se encuentra el botón de agregar, se le agrega un evento click que llamará al método addInstance()
        if ($addButton) {
            $addButton.addEventListener('click', () => this.addInstance($element));
        }
    }

    // Método para cargar instancias previamente creadas
    loadInstances($element, $prevOptions) {
        // Parsea el JSON con los datos de las instancias
        $prevOptions = JSON.parse($prevOptions);
        console.log($prevOptions, 'prev');
        // agrega las instancias necesariascon los datos proporcionados
        $prevOptions.forEach(($option) => this.addInstance($element, $option));
    }

    // Método para agregar una nueva instancia del template
    addInstance($element, $option = null) {
        // Busca el primer que es hijo del elemento padre o el configurado en las opciones del
        // componente o en la propiedad data-mtm-option-template
        const $optionTemplate = this.searchTemplate($element);
        // Clona el template encontrado
        const $content = $optionTemplate.content.cloneNode(true);
        // Carga los datos de la instancia si se proporcionaron en $option si $option fue recibido
        this.loadInstanceData($content, $option);
        // Genera un identificador único para la instancia y establece ese ID en el template clonado
        const $instanceId = 'instance-' + Date.now();
        // Crear un contenedor para el template
        const $tplContainer = document.createElement('div');
        // Establece el ID de la instancia en el elemento padre
        $tplContainer.id = $instanceId;
        $tplContainer.dataset.ncMtmInstance = true;

        // Agrega el template clonado al contenedor
        $tplContainer.appendChild($content);
        // Busca el botón de eliminar en la instancia clonada y, si existe, le agrega un evento click que llamará al método deleteInstance()
        const $deleteButton = $tplContainer.querySelector('[data-nc-mtm-action="delete-option"]');

        //verifica si existe el botón de eliminar
        if ($deleteButton) {
            // Si existe, le agrega un evento click que llamará al método deleteInstance()
            $deleteButton.addEventListener('click', () => this.deleteInstance($element, $instanceId));
        }

        // Agrega un campo oculto (input tipo 'hidden') que almacenará el ID de la instancia en el elemento padre
        this.addOrderField($element, $tplContainer);
        // Busca el contenedor de instancias y agrega la nueva instancia clonada al final
        const $optionContainer = $element.querySelector('[data-nc-mtm="option-container"]');
        // Agrega el template al contenedor
        $optionContainer.appendChild($tplContainer);
        // Agrega el ID de la instancia a la lista de IDs almacenados
        this.instanceIds.push($instanceId);
        // Reescribe los nombres de los campos de la instancia
        this.rewriteNameFields($element, $optionContainer);
        // Actualiza el campo oculto con los IDs de las instancias para enviarlos en el formulario
        this.updateOrderField($element);
    }

    // Método para cargar los datos de una instancia
    loadInstanceData($content, $option) {

        // Busca todos los elementos con el atributo name
        const $fields = $content.querySelectorAll('[name]');

        // Recorre todos los elementos con el atributo name
        $fields.forEach(($field) => {
            if($option){
                // obtener el id del campo
                const $fieldId = $field.getAttribute('id');
                // buscar en la opción el valor del campo
                const $optionField = $option[$fieldId] || null;
                // Obtener el tipo de campo
                const $fieldType = $field.getAttribute('type');
                // Verifica si el campo es de tipo checkbox
                if ($fieldType === 'checkbox') {
                    // Si es checkbox, verifica si el valor del campo es el mismo que el valor de la opción
                    if ($optionField == $field.value) {
                        // Si es true, establece el atributo checked en el campo
                        $field.setAttribute('checked', true);
                    }
                } else {
                    // Si no es checkbox, establece el valor del campo
                    $field.value = $optionField;
                }
            }
        });
    }

    /**
     * Busca el primer que es hijo del elemento padre o
     * el configurado en las opciones del componente o
     * en la propiedad data-mtm-option-template
     *
     * @param {*} $element
     */
    searchTemplate($element) {
        // Obtiene el selector del template de la configuración
        let $tplSelector = this.getConfigParam($element, 'optionTemplate');
        // Inicializa la variable que almacenará el template
        let $template = null;

        // Verifica si el parámetro de configuración es null
        if ($tplSelector == null) {
            // Si es null, busca el primer <template> que es hijo del elemento padre
            $template = $element.querySelector('template');
        } else {
            // Si no es null, busca el <template> con el selector proporcionado
            $template = document.querySelector($tplSelector);
        }

        // verifica si existe el elemento
        if ($template) {
            // Si existe, retorna el elemento
            return $template;
        } else {
            // Si no existe genera un mensaje de error
            nuc.de('No se encontró el template para agregar instancias');
            // detiene la ejecución
            return null;
        }

    }

    rewriteNameFields($element, $instanceContainer) {
        // Establece la expresión regular para verificar que el nombre del campo sea del tipo text[0][text]
        const $regex = /^[^\[\]]+\[\d+\]\[[^\[\]]+\]$/;
        // Obtiene el nombre para el campo desde la configuración o desde el elemento padre
        let $newNameField = this.getConfigParam($element, 'name');
        // Obtiene todas las instancias del contenedor
        let $instances = $instanceContainer.querySelectorAll('[data-nc-mtm-instance="true"]');

        // Recorre todas las instancias
        $instances.forEach(($instance, $index) => {
            // obtener todos los elementos con el atributo name de la instancia
            let $fields = $instance.querySelectorAll('[name]');

            // Recorre todos los elementos con el atributo name
            $fields.forEach(($field) => {
                // Obtiene el nombre del campo
                let $name = $field.getAttribute('name');

                // Verifica si el nombre del campo cumple con el patrón
                if ($regex.test($name)) {
                    // Si cumple, actualiza el número en el nombre del campo
                    let $newName = $name.replace(/\d+/, $index);
                    // Establece el nuevo nombre del campo
                    $field.setAttribute('name', $newName);
                } else {
                    // Si no cumple, convierte el nombre del campo a snake_case
                    let $camelCaseName = changeCase.snakeCase($name);
                    // Establece el nuevo nombre del campo
                    let $newName = $newNameField + '[' + $index + '][' + $camelCaseName + ']';
                    // Si no cumple, establece el nuevo nombre directamente
                    $field.setAttribute('name', $newName);
                }
            });

        });
    }
    // Método para agregar el campo oculto con el ID de la instancia
    addOrderField($element, $tplContainer) {
        // Crea un input tipo 'hidden' con el nombre y el valor del ID de la instancia
        const $orderField = document.createElement('input');
        // Agrega el tipo de campo oculto al elemento padre
        $orderField.type = 'hidden';
        // Obtiene el nombre del campo oculto de la configuración o del elemento padre
        const $orderFieldName = this.getConfigParam($element, 'orderField');
        // Establece el nombre y el valor del campo oculto con los IDs de las instancias separados por comas
        $orderField.setAttribute('name', $orderFieldName);
        // Establece el nombre de campo con el valor de la propiedad orderField o data-mtm-order-field del elemento padre
        $orderField.name = $orderFieldName || 'order';
        // Agrega el campo Orden al elemento instanciado del template
        $tplContainer.appendChild($orderField);
    }

    // Método para eliminar una instancia
    deleteInstance($element, $instanceId) {
        // Busca el elemento con el ID de la instancia
        const $instance = $element.querySelector('#' + $instanceId);

        if ($instance) {
            // Remueve la instancia del DOM
            $instance.remove();

            // Busca el índice del ID de la instancia en el arreglo instanceIds
            const $index = this.instanceIds.indexOf($instanceId);
            if ($index !== -1) {
                // Si el ID existe en el arreglo, lo elimina de la lista
                this.instanceIds.splice($index, 1);
            }
        }

        // Busca el contenedor de instancias y agrega la nueva instancia clonada al final
        const $optionContainer = $element.querySelector('[data-nc-mtm="option-container"]');
        // Reescribe los nombres de los campos de la instancia
        this.rewriteNameFields($element, $optionContainer);
        // Actualiza el campo oculto con los IDs de las instancias para enviarlos en el formulario
        this.updateOrderField($element);
    }

    // Método para actualizar el campo oculto con los IDs de las instancias y sus campos de orden
    updateOrderField($element) {
        // Obtiene el nombre del campo oculto de la configuración o del elemento padre
        const $orderFieldName = this.getConfigParam($element, 'orderField');
        // const $orderFields = $element.querySelectorAll("[name=" + $orderFieldName + "]");
        const $orderFields = $element.querySelectorAll(`[name*="${$orderFieldName}"]`);

        // Verifica si se encontro el campo oculto de orden
        if ($orderFields.length > 0) {
            // Reiniciar el contador al valor inicial (1)
            let count = 1;

            // Recorrer los campos de orden y establecer nuevamente sus valores
            $orderFields.forEach(($orderField) => {
                $orderField.setAttribute('value', count++);
            });

            // Establecer el próximo valor de campo de orden
            this.nextOrderValue = count;
        }
    }
}

// Mezcla los métodos del mixin en la clase
Object.assign(ManyToManySelector.prototype, $_generalMixins);


// Exporta la clase ManyToManySelector para que pueda ser utilizada por otros módulos si es necesario
module.exports = ManyToManySelector;
