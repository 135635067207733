import $ from 'jquery';

// Seleccionar todos los elementos con la clase .table-action-default
var tableActionDefaultElements = $('.table-action-default');

// Verificar si existen elementos .table-action-default
if (tableActionDefaultElements.length > 0) {
  // Si hay elementos .table-action-default

  // Asociar eventos hover a los elementos .table-action-default
  tableActionDefaultElements.hover(function() {
    // Cuando se pasa el mouse por encima de un elemento .table-action-default
    $(this).closest('tr').addClass('highlight');  // Agregar la clase 'highlight' a la fila (tr) más cercana
  }, function() {
    // Cuando se mueve el mouse fuera de un elemento .table-action-default

    $(this).closest('tr').removeClass('highlight');  // Eliminar la clase 'highlight' de la fila (tr) más cercana
  });
}