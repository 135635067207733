// Importar la clase SwapContent
// import SwapContent from './SwapContent';
const SwapContentRequire = require('./SwapContent.js');
// agrega un listener al contenido cuando se carga el DOM...
document.addEventListener('DOMContentLoaded', function() {
    // ... e inicializa los botones
    new SwapContent();
});

// export default SwapContent;
window.SwapContent = SwapContentRequire;

 