document.addEventListener('DOMContentLoaded', function() {
    // obtiene el modal para eliminar registros
    var $modalDelete = $('#deleteDialog');

    if ($modalDelete.length) {
        // Obtener el botón de eliminación
        var deleteBtn = $('.text-danger');
        // Obtener el campo de entrada del ID en el modal
        var deleteInput = $('#valor-confirmacion');
        // Obtener el formulario de eliminación
        var deleteForm = $('#delete-form');
        // Obtener el elemento de confirmación de frase
        var confirmationFrase = $('#confirmation-frase');

        // Agregar un controlador de eventos "submit" al formulario de eliminación
        deleteForm.submit(function(event) {
            // Evitar que se envíe el formulario de eliminación si el campo de confirmación está vacío
            if (!deleteInput.val()) {
                event.preventDefault();
            }
        });

        // Agregar un controlador de eventos "click" al botón
        deleteBtn.click(function(event) {
            // Obtener el ID del registro seleccionado del atributo "data-id"
            var idToDelete = $(this).data('id');
            // Obtener la URL de eliminación del atributo "data-action"
            var deleteUrl = $(this).data('action');
            // Actualizar el campo de entrada del ID en el modal
            deleteInput.val('');
            // Reiniciar el campo de entrada del ID en el modal y eliminar cualquier mensaje de error
            deleteInput.val('').removeClass('is-invalid');
            // Eliminar cualquier mensaje de error del campo de entrada del ID en el modal
            deleteInput.siblings('.invalid-feedback').text('');
            // Actualizar el atributo "action" del formulario de eliminación
            deleteForm.attr('action', deleteUrl);
            // Insertar el ID del registro en la confirmación-frase
            confirmationFrase.text(idToDelete);
            // Agregar el ID del registro como atributo "data-id" en la confirmación-frase
            confirmationFrase.text(idToDelete).attr('data-id', idToDelete);
        });

        // Agregar un controlador de eventos "click" al botón de confirmación en el modal
        $('#deleteDialog .btn-outline-danger').click(function() {
            // Obtener el ID ingresado en el campo de entrada
            var confirmId = deleteInput.val().trim();
            // Obtener el ID del registro seleccionado del atributo "data-id"
            var idToDelete = confirmationFrase.data('id');
            // Comprobar si el ID ingresado coincide con el ID del registro seleccionado
            console.log(confirmId);
            console.log(idToDelete);
            console.log(confirmId === idToDelete);
            if (confirmId === idToDelete) {
                // Enviar el formulario de eliminación
                deleteForm.submit();
            } else {
                // Mostrar un mensaje de error si el ID ingresado no coincide
                deleteInput.addClass('is-invalid');
                deleteInput.siblings('.invalid-feedback').text('El ID ingresado no coincide con el del registro seleccionado');
            }
        });

        // Obtener el elemento de confirmación de frase
        var elementoTexto = $("#confirmation-frase");

        // Agrega el evento de clic al elemento de texto
        elementoTexto.click(function() {

            // Selecciona el texto
            var seleccion = window.getSelection();
            var rango = document.createRange();
            rango.selectNodeContents(elementoTexto.get(0));
            seleccion.removeAllRanges();
            seleccion.addRange(rango);

            // Copia el texto al portapapeles
            document.execCommand("copy");

            // Deselecciona el texto
            seleccion.removeAllRanges();

            // Notificación de éxito
            // alert("El texto se ha copiado al portapapeles");

            // Muestra el tooltip de éxito
            elementoTexto.tooltip('show');

        });

    } else {
        // console.log("El elemento no fue encontrado.");
    }
});