import $ from 'jquery';


$('.open-ofc-light-sheet').click(function() {
    let rowId = $(this).data('row-id');
    $("table tr").removeClass("table-row-sheet-active");
    let tableRow = $('#' + rowId);
    tableRow.addClass('table-row-sheet-active');
    let offCanvasContent = $('#cow-data-sheet');
    console.log(offCanvasContent, tableRow.data('light-sheet'));

    // $('.table-row[id="' + rowId + '"]').addClass('table-secondary');

    offCanvasContent.load(tableRow.data('light-sheet'), function() {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new bootstrap.Tooltip(tooltipTriggerEl);
        });
    });   
});