import $ from 'jquery';


// Espera a que se cargue el evento 'load'
window.addEventListener('load', function () {
    let $loader = document.querySelectorAll('[data-role="loader"]');
    $loader.forEach(function (element) {
        // Agrega la clase 'fade-out' para iniciar la animación
        element.classList.add('fade-out');

        // Después de un tiempo (por ejemplo, 0.5 segundos), puedes eliminar el elemento si es necesario
        setTimeout(function () {
            element.style.display = 'none';
        }, 500);
    });
});
