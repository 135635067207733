// Importar la clase MercureClient
import MercureClient from './MercureClient';

// agrega un listener al contenido cuando se carga el DOM...
document.addEventListener('DOMContentLoaded', function () {
    // ... e inicializa los botones
    const mercureClientInstance = new MercureClient();
});

export default MercureClient;
