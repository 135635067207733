/**
 * @fileoverview This class handles the layout creation of a frequency component.
 *
 * @author Wilson Fabian Pérez Sucuzhañay
 * @contact wilsonperez.developer@gmail.com, wperez@cintanegra.net
 * @copyright 2023 @wilodev
 *
 * @class Frequency Component
 */

class FrequencyComponent {

    /**
     * Función constructora del componente
     * @param {id} id Id del elemento que contiene el componente de tipo frequency-app
     * @param {data} data Objeto con los datos del componente ver en getDataFromElement esta cada elemento a enviar
     */
    constructor(id, data = null) {
        this.container = null;
        this.template = null;
        this.id = id;
        this.json = null;
        this.randomIdValue = this.getRandomId();
        this.frequencyId = null;
        // Agregar un estado para el valor seleccionado del radio
        this.selectedRadioOption = null;
        // Agregar un estado para el valor seleccionado del radio
        this.selectedRadioValue = null;
        // Agregar un estado para el valor seleccionado del select diario
        this.selectedDailyOption = null;
        // Se agrega un estado para el valor seleccionado del select weekly
        this.selectedWeeklyOption = null;
        this.checkboxDayOfWeekOption = null;
        // Se agrega un estado para el valor seleccionado del select monthly
        this.selectedMonthlyOption = null;
        this.checkboxDayOfMonthOption = null
        // Se agrega un estado para el valor seleccionado del select yearly
        this.selectedYearlyOption = null;
        this.checkboxMonthOfYearOption = null;
        // Se agrega un estado para el valor seleccionado si tendrá días del mes de tipo true o false (checkbox)
        this.selectedHasDaysMonthOption = null;
        // Se agrega un estado para el valor seleccionado de los días del mes
        this.checkboxDayOfMonthOfYearOption = null;
        this.data = data || this.getDataFromElement(id);
    }

    /**
     * Función para obtener la data si no se pasa en el constructor en base al id del elemento que contiene el componente
     * @param {string} id
     * @returns
     */
    getDataFromElement = (id) => {
        const element = document.querySelector(`#${this.id}`);
        if (element) {
            return {
                // Id del elemento que contiene el componente
                id: element.dataset.id,
                // Texto por defecto a mostrar en los selects de frecuencia
                defaultOptionText: element.dataset.defaultOptionText,
                // Título por defecto a mostrar en el componente
                title: element.dataset.title,
                // Nombre del componente
                name: element.dataset.name,
                // Clase que se pondrá al componente
                classInput: element.dataset.classInput,
                // Lenguaje del componente para mostrar los textos
                language: element.dataset.language,
                // Label del componente
                label: element.dataset.label,
                // Clase que se pondrá al label del componente
                classLabel: element.dataset.classLabel,
                // Mensaje que se mostrará al final del componente
                message: element.dataset.message,
                // Clase que se pondrá al mensaje del componente
                classMessage: element.dataset.classMessage,
                // Estado del componente
                status: element.dataset.status,
                // Elemento Hidden que tendrá el JSON con los datos del componente
                hidden: element.dataset.hidden,
                // Botón que se mostrará al final del componente
                button: element.dataset.button,
                // Opciones que se mostrarán en el primer select y el resto de selects en base a la opción seleccionada
                options: element.dataset.options ? JSON.parse(element.dataset.options) : null,
                // Opción para el ending del componente
                ending: element.dataset.ending ? JSON.parse(element.dataset.ending) : null,
                // Valores que se enviarán en el hidden del componente
                hiddenValue: element.dataset.hiddenValue ? JSON.parse(element.dataset.hiddenValue) : null,
                // Id del elemento que se mostrará el componente si es externo
                externalIdShow: element.dataset.externalIdShow,
                // Id del componente padre donde se va a empezar a renderizar el componente
                parentId: element.dataset.parentId,
            };
        }
        console.error('Container element not found.');
        return;
    };

    /**
     * Función para inicializar el componente y verificar si tiene data
     */
    init = () => {
        this.container = document.querySelector(`#${this.data.id}`);
        if (!this.container) {
            console.error('Container element not found.');
            return;
        }
        this.template = document.querySelector('#frequency-component-template');
        this.createComponent();
        this.checkJsonData();
    };

    /**
     * Función para crear el componente de la frecuencia
     * */
    createComponent = () => {
        const componentClone = document.importNode(this.template.content, true);
        this.container.appendChild(componentClone);
        // En el container se busca el que tiene el id title-frequency para agregarle el título.
        const titleFrequency = this.container.querySelector(`#title-frequency`);
        titleFrequency.textContent = this.data.title;
        // Se carga el primer select con los valores de la data
        this.setupFirstSelect();
    };

    /**
     *  Función para cargar el valor del select de la frecuencia
     */
    setupFirstSelect = () => {
        const firstSelect = this.container.querySelector(`#select-frequency`);
        firstSelect.id = `${this.data.name}-${this.data.id}-${this.randomIdValue}`;
        // Se añade el label al que tiene el id label-frequency y se le añade la clase que tiene el componente
        const labelFrequency = this.container.querySelector(`#label-frequency`);
        labelFrequency.textContent = this.data.label;
        labelFrequency.setAttribute('for', `${this.data.name}-${this.data.id}-${this.randomIdValue}`);
        // Se añade un evento de escucha al cambio del select
        firstSelect.addEventListener('change', this.handleFirstSelectChange);
        // Se llena el select con las opciones
        this.populateFirstSelect(firstSelect);
    };

    /**
     * Función para renderizar el componente de tipo frequency de forma diaria
     * @param {event} value
     * @param {boolean} external
     */
    handleFirstSelectChange = (value) => {
        const selectedOption = parseInt(value.target.value);
        this.clearComponent();
        // Si el valor es 0 se limpia el componente
        if (isNaN(selectedOption)) {
            //   Se debe limpiar los hijos del componente container
            this.clearComponent();
            this.frequencyId = null;
        } else {
            this.frequencyId = selectedOption;
            if (selectedOption === 1) {
                // Si el valor es 1 se hace render el componente de tipo frequency de forma diaria
                this.renderDailyFrequencyComponent();
            } else if (selectedOption === 2) {
                // Si el valor es 2 se hace render el componente de tipo frequency de forma semanal
                this.renderWeeklyFrequencyComponent();
            } else if (selectedOption === 3) {
                // Si el valor es 3 se hace render el componente de tipo frequency de forma mensual
                this.renderMonthlyFrequencyComponent();
            } else if (selectedOption === 4) {
                // Si el valor es 4 se hace render el componente de tipo frequency de forma anual
                this.renderYearlyFrequencyComponent();
            }
            // Se hacer render el componente de tipo endings
            this.renderEndingComponent();
        }
    };

    /**
     * Función que duplica el comportamiento anterior de handleFirstSelectChange porque en edición no debería limpiar el json
     * */
    handleFirstSelectChangeLocal = (value) => {
        const selectedOption = parseInt(value.target.value);
        // Si el valor es 0 se limpia el componente
        if (isNaN(selectedOption)) {
            //   Se debe limpiar los hijos del componente container
            this.clearComponent();
            this.frequencyId = null;
        } else {
            this.frequencyId = selectedOption;
            if (selectedOption === 1) {
                // Si el valor es 1 se hace render el componente de tipo frequency de forma diaria
                this.renderDailyFrequencyComponent();
            } else if (selectedOption === 2) {
                // Si el valor es 2 se hace render el componente de tipo frequency de forma semanal
                this.renderWeeklyFrequencyComponent();
            } else if (selectedOption === 3) {
                // Si el valor es 3 se hace render el componente de tipo frequency de forma mensual
                this.renderMonthlyFrequencyComponent();
            } else if (selectedOption === 4) {
                // Si el valor es 4 se hace render el componente de tipo frequency de forma anual
                this.renderYearlyFrequencyComponent();
            }
            // Se hacer render el componente de tipo endings
            this.renderEndingComponent();
        }
    };

    /**
     * Función para limpiar los componentes generados así como los textos
     */
    clearComponent = () => {
        if (this.container) {
            // Se limpia el componente con id children que esta dentro de container
            const children = this.container.querySelector(`#children`);
            const childrenEnding = this.container.querySelector(`#children-ending`);
            const jsonHidden = this.container.querySelector(`#${this.data.hidden}`);
            jsonHidden.value = '';
            children.innerHTML = '';
            childrenEnding.innerHTML = '';
            if (this.container.querySelector(`#message-details-${this.data.id}`)) {
                const childrenText = this.container.querySelector(`#message-details-${this.data.id}`);
                childrenText.textContent = '';
            }
            // Se limpia las variables globales
            this.selectedRadioOption = null;
            this.selectedRadioValue = null;
            this.selectedDailyOption = null;
            this.selectedWeeklyOption = null;
            this.checkboxDayOfWeekOption = null;
            this.selectedMonthlyOption = null;
            this.checkboxDayOfMonthOption = null;
            this.selectedYearlyOption = null;
            this.checkboxMonthOfYearOption = null;
            this.selectedHasDaysMonthOption = null;
            this.checkboxDayOfMonthOfYearOption = null;
            this.json = null;
        }
    }

    /**
     * Función para agregar la primera opción en el selector
     */
    addFirstOptionInSelect = (
        select,
    ) => {
        const defaultOption = document.createElement('option');
        defaultOption.value = '';
        defaultOption.textContent = this.data.defaultOptionText;
        select.appendChild(defaultOption);
    }

    /**
     * Función para llenar el primer select con las opciones
     */
    populateFirstSelect = (firstSelect) => {
        const optionsData = this.data.options;
        // Poner por defecto el valor de la data defaultOptionText
        this.addFirstOptionInSelect(firstSelect);
        // Se llena el select con las opciones
        for (const [optionId, option] of Object.entries(optionsData)) {
            const optionElement = document.createElement('option');
            optionElement.value = option.id;
            optionElement.textContent = option.name;
            firstSelect.appendChild(optionElement);
        }
    };

    /**
     * Función para generar un id random
     * @returns number
     */
    getRandomId = () => {
        return Math.floor(Math.random() * 1000000000);
    }

    /**
     * Función para crear el componente de tipo diario y agregarlo en el div children del container
     * */
    renderDailyFrequencyComponent = () => {
        const dailyComponentTemplate = document.querySelector('#frequency-daily-template');
        const componentClone = document.importNode(dailyComponentTemplate.content, true);
        const labelDailyFrequency = componentClone.querySelector(`#label-daily`);
        labelDailyFrequency.textContent = this.data.options[0].elements[0].label;
        const selectDailyFrequency = componentClone.querySelector(`#select-daily`);
        // Se cambia el id y el name del select
        selectDailyFrequency.id = `${this.data.options[0].name}-${this.randomIdValue}`;
        selectDailyFrequency.name = `${this.data.options[0].name}-${this.randomIdValue}`;
        // Se añade la option por defecto del select
        this.addFirstOptionInSelect(selectDailyFrequency);
        // Se agregar las options al select creando un for de 1 a 999
        for (let i = this.data.options[0].elements[0].optionsFromTo.from; i <= this.data.options[0].elements[0].optionsFromTo.to; i++) {
            const optionElement = document.createElement('option');
            optionElement.value = i;
            optionElement.textContent = i;
            selectDailyFrequency.appendChild(optionElement);
        }
        const children = this.container.querySelector(`#children`);
        selectDailyFrequency.addEventListener('change', this.handleSelectDailyChange);
        children.appendChild(componentClone);
    }

    /**
     * Función
     * @param {*} event
     */
    handleSelectDailyChange = (event) => {
        const value = event.target.value;
        if (value) {
            this.selectedDailyOption = value;
            if (this.selectedRadioOption) {
                this.generateText();
            }
        }
    }

    /**
     * Función para generar el componente de semanal y agregarlo en el div children del container
     **/
    renderWeeklyFrequencyComponent = () => {
        const weeklyComponentTemplate = document.querySelector('#frequency-weekly-template');
        const componentClone = document.importNode(weeklyComponentTemplate.content, true);
        const labelWeeklyFrequency = componentClone.querySelector(`#label-weekly`);
        labelWeeklyFrequency.id = `label-weekly-${this.randomIdValue}`;
        labelWeeklyFrequency.textContent = this.data.options[1].elements[0].label;
        const selectWeeklyFrequency = componentClone.querySelector(`#select-weekly`);
        // Se cambia el id y el name del select
        selectWeeklyFrequency.id = `${this.data.options[1].name}-${this.randomIdValue}`;
        selectWeeklyFrequency.name = `${this.data.options[1].name}-${this.randomIdValue}`;
        // Se añade la option por defecto del select
        this.addFirstOptionInSelect(selectWeeklyFrequency);
        // Se agregar las options al select creando un for de 1 a 999
        for (let i = this.data.options[1].elements[0].optionsFromTo.from; i <= this.data.options[1].elements[0].optionsFromTo.to; i++) {
            const optionElement = document.createElement('option');
            optionElement.value = i;
            optionElement.textContent = i;
            selectWeeklyFrequency.appendChild(optionElement);
        }
        const children = this.container.querySelector(`#children`);
        // Se busca el div con clase days-of-week
        const childrenDaysOfWeek = componentClone.querySelector(`#days-of-week`);
        childrenDaysOfWeek.id = `days-of-week-${this.randomIdValue}`;
        // Se obtiene los días de la semana en base aun idioma.
        const daysOfWeek = this.getDaysOfWeek(this.data.language);
        // Se recorre el array de días para crear el calendario con el template dayOfWeekTemplate y se añade al childrenDaysOfWeek
        for (const [dayId, day] of Object.entries(daysOfWeek)) {
            // Se clona el template con id frequency-day-of-week
            const dayOfWeekTemplate = document.querySelector('#frequency-day-of-week');
            const dayOfWeekClone = document.importNode(dayOfWeekTemplate.content, true);
            const labelDayOfWeek = dayOfWeekClone.querySelector(`#label-day-of-week`);
            const checkboxDayOfWeek = dayOfWeekClone.querySelector(`#checkbox-day-of-week`);
            labelDayOfWeek.id = `label-${day}-${this.randomIdValue}`;
            // Se añade el for
            labelDayOfWeek.setAttribute('for', `${day}-${this.randomIdValue}`);
            // Se corta el string para que solo muestra la inicial en mayúsculas
            labelDayOfWeek.textContent = day.slice(0, 1).toUpperCase();
            checkboxDayOfWeek.id = `${day}-${this.randomIdValue}`;
            checkboxDayOfWeek.name = `${day}-${this.randomIdValue}`;
            checkboxDayOfWeek.value = day;
            checkboxDayOfWeek.addEventListener('change', (event) => this.handleCheckboxChangeWeek(event, parseInt(dayId) + 1), this.checkboxDayOfWeekOption);
            childrenDaysOfWeek.appendChild(dayOfWeekClone);
        }
        // Se añade un evento de escucha al cambio del select
        selectWeeklyFrequency.addEventListener('change', this.handleSelectChangeWeek);
        // Se añade todo el componente al children
        children.appendChild(componentClone);
    }

    /**
     * Función para un evento de escucha en el select cuando cambien el select de la semana
     * @param {*} event
     */
    handleSelectChangeWeek = (event) => {
        const value = event.target.value;
        if (value) {
            this.selectedWeeklyOption = value;
            if (this.selectedRadioOption) {
                this.generateText();
            }
        }
    }

    /**
     * Función para un evento de escucha en el checkbox cuando añaden o quitan un día de la semana
     * @param {*} event
     * @param {*} index
     */
    handleCheckboxChangeWeek = (event, index) => {
        if (!this.checkboxDayOfWeekOption) {
            this.checkboxDayOfWeekOption = [];
        }
        const value = event.target.value;
        const checked = event.target.checked;
        if (checked) {
            this.checkboxDayOfWeekOption.push({ index, value });
        } else {
            // Se quita del this.checkboxDayOfWeekOption el elemento que se desmarcó
            this.checkboxDayOfWeekOption = this.checkboxDayOfWeekOption.filter((item) => item.index !== index);
        }
        // se ordena el this.checkboxDayOfWeekOption por index
        this.checkboxDayOfWeekOption.sort((a, b) => a.index - b.index);
        if (this.selectedRadioOption) {
            this.generateText();
        }
    }

    /**
     * Función para generar el componente de mensual y agregarlo en el div children del container
     **/
    renderMonthlyFrequencyComponent = () => {
        const monthlyComponentTemplate = document.querySelector('#frequency-monthly-template');
        const componentClone = document.importNode(monthlyComponentTemplate.content, true);
        const labelMonthlyFrequency = componentClone.querySelector(`#label-monthly`);
        labelMonthlyFrequency.textContent = this.data.options[1].elements[0].label;
        const selectMonthlyFrequency = componentClone.querySelector(`#select-monthly`);
        // Se cambia el id y el name del select
        selectMonthlyFrequency.id = `${this.data.options[2].name}-${this.randomIdValue}`;
        selectMonthlyFrequency.name = `${this.data.options[2].name}-${this.randomIdValue}`;
        // Se añade la option por defecto del select
        this.addFirstOptionInSelect(selectMonthlyFrequency);
        // Se agregar las options al select creando un for de 1 a 999
        for (let i = this.data.options[2].elements[0].optionsFromTo.from; i <= this.data.options[2].elements[0].optionsFromTo.to; i++) {
            const optionElement = document.createElement('option');
            optionElement.value = i;
            optionElement.textContent = i;
            selectMonthlyFrequency.appendChild(optionElement);
        }
        const children = this.container.querySelector(`#children`);
        // Se busca el div con clase days-of-month
        const childrenDaysOfMonth = componentClone.querySelector(`#days-of-month`);
        childrenDaysOfMonth.id = `days-of-month-${this.randomIdValue}`;
        // Se recorre el array de días para crear el calendario con el template dayOfMonthTemplate y se añade al childrenDaysOfMonth
        for (let day = 1; day < 32; day++) {
            let dayOfMonthTemplate = null;
            let dayOfMonthClone = null;
            let labelDayOfMonth = null;
            let checkboxDayOfMonth = null;
            // Se clona el template con id frequency-day-of-Month
            dayOfMonthTemplate = document.querySelector('#frequency-day-of-month');
            dayOfMonthClone = document.importNode(dayOfMonthTemplate.content, true);
            labelDayOfMonth = dayOfMonthClone.querySelector(`#label-day-of-month`);
            checkboxDayOfMonth = dayOfMonthClone.querySelector(`#checkbox-day-of-month`);
            labelDayOfMonth.id = `label-day-of-month-${day}-${this.randomIdValue}`;
            // Se añade el for
            labelDayOfMonth.setAttribute('for', `checkbox-day-of-month-${day}-${this.randomIdValue}`);
            // Se corta el string para que solo muestra la inicial en mayúsculas
            labelDayOfMonth.textContent = day;
            checkboxDayOfMonth.id = `checkbox-day-of-month-${day}-${this.randomIdValue}`;
            checkboxDayOfMonth.name = `checkbox-day-of-month-${day}-${this.randomIdValue}`;
            checkboxDayOfMonth.value = day;
            checkboxDayOfMonth.addEventListener('click', this.handleCheckboxChangeMonth);
            childrenDaysOfMonth.appendChild(dayOfMonthClone);
        }
        // Se añade un evento de escucha al cambio del select
        selectMonthlyFrequency.addEventListener('change', this.handleSelectChangeMonth);
        // Se añade todo el componente al children
        children.appendChild(componentClone);
    }

    /**
     * Función para un evento de escucha en el select cuando cambien el select del mes
     * @param {*} event
     */
    handleSelectChangeMonth = (event) => {
        const value = event.target.value;
        if (value) {
            this.selectedMonthlyOption = value;
            if (this.selectedRadioOption) {
                this.generateText();
            }
        }
    }

    /**
     * Función para un evento de escucha en el checkbox cuando añaden o quitan un día del mes
     * @param {*} event
     */
    handleCheckboxChangeMonth = (event) => {
        if (this.checkboxDayOfMonthOption === null) {
            this.checkboxDayOfMonthOption = [];
        }
        const value = event.target.value;
        const checked = event.target.checked;
        if (checked) {
            this.checkboxDayOfMonthOption.push(value);
        } else {
            // Se quita del this.checkboxDayOfMonthOption el elemento que se desmarcó
            this.checkboxDayOfMonthOption = this.checkboxDayOfMonthOption.filter((item) => item !== value);
        }
        // se ordena el this.checkboxDayOfMonthOption por index
        this.checkboxDayOfMonthOption.sort((a, b) => a - b);
        if (this.selectedRadioOption) {
            this.generateText();
        }
    }

    /**
     * Función para generar el componente de anual y agregarlo en el div children del container
     **/
    renderYearlyFrequencyComponent = () => {
        const yearlyComponentTemplate = document.querySelector('#frequency-yearly-template');
        const componentClone = document.importNode(yearlyComponentTemplate.content, true);
        const labelYearlyFrequency = componentClone.querySelector(`#label-yearly`);
        labelYearlyFrequency.textContent = this.data.options[1].elements[0].label;
        const selectYearlyFrequency = componentClone.querySelector(`#select-yearly`);
        // Se cambia el id y el name del select
        selectYearlyFrequency.id = `${this.data.options[3].name}-${this.randomIdValue}`;
        selectYearlyFrequency.name = `${this.data.options[3].name}-${this.randomIdValue}`;
        // Se añade la option por defecto del select
        this.addFirstOptionInSelect(selectYearlyFrequency);
        // Se agregar las options al select creando un for de 1 a 999
        for (let i = this.data.options[3].elements[0].optionsFromTo.from; i <= this.data.options[3].elements[0].optionsFromTo.to; i++) {
            const optionElement = document.createElement('option');
            optionElement.value = i;
            optionElement.textContent = i;
            selectYearlyFrequency.appendChild(optionElement);
        }
        const children = this.container.querySelector(`#children`);
        // Se busca el div con clase days-of-month
        const childrenMonthsOfYear = componentClone.querySelector(`#months-of-year`);
        // Se obtiene los días de la semana en base aun idioma.
        const monthsOfYear = this.getMonthsOfYear(this.data.language);
        // Se recorre el array de días para crear el calendario con el template dayOfWeekTemplate y se añade al childrenDaysOfWeek
        for (const [monthId, month] of Object.entries(monthsOfYear)) {
            let monthOfYearTemplate = null;
            let monthOfYearClone = null;
            let labelMonthOfYear = null;
            let checkboxMonthOfYear = null;
            // Se clona el template con id frequency-day-of-Month
            monthOfYearTemplate = document.querySelector('#frequency-day-of-month-of-year');
            monthOfYearClone = document.importNode(monthOfYearTemplate.content, true);
            labelMonthOfYear = monthOfYearClone.querySelector(`#label-day-of-month-of-year`);
            checkboxMonthOfYear = monthOfYearClone.querySelector(`#checkbox-day-of-month-of-year`);
            labelMonthOfYear.id = `label-month-of-year-${month}-${this.randomIdValue}`;
            // Se añade el for
            labelMonthOfYear.setAttribute('for', `checkbox-month-of-year-${month}-${this.randomIdValue}`);
            // Se corta el string para que solo muestra la inicial en mayúsculas
            labelMonthOfYear.textContent = month.slice(0, 3).toUpperCase();
            checkboxMonthOfYear.id = `checkbox-month-of-year-${month}-${this.randomIdValue}`;
            checkboxMonthOfYear.name = `checkbox-month-of-year-${month}-${this.randomIdValue}`;
            checkboxMonthOfYear.value = month;
            checkboxMonthOfYear.addEventListener('change', (event) => this.handleCheckboxChangeMonthOfYear(event, parseInt(monthId) + 1));
            childrenMonthsOfYear.appendChild(monthOfYearClone);
        }
        const componentHasDaysOfMonth = componentClone.querySelector(`#days-of-month-of-year`);
        componentHasDaysOfMonth.id = `days-of-month-of-year-${this.randomIdValue}`;
        // Se añade un id del component
        componentHasDaysOfMonth.id = `days-of-month-of-year-${this.randomIdValue}`;
        const hasDaysOfMonth = componentClone.querySelector(`#label-has-days-of-month-of-year`);
        hasDaysOfMonth.id = `label-has-days-of-month-of-year-${this.randomIdValue}`;
        hasDaysOfMonth.textContent = this.data.options[3].elements[2].label;
        // Se le añade el for al label
        hasDaysOfMonth.setAttribute('for', `checkbox-has-days-of-month-of-year-${this.randomIdValue}`);
        const checkboxHasDaysOfMonth = componentClone.querySelector(`#checkbox-has-days-of-month-of-year`);
        checkboxHasDaysOfMonth.id = `checkbox-has-days-of-month-of-year-${this.randomIdValue}`;
        checkboxHasDaysOfMonth.name = `checkbox-has-days-of-month-of-year-${this.randomIdValue}`;
        // Se añade un evento de escucha al cambio del checkbox
        checkboxHasDaysOfMonth.addEventListener('change', (event) => this.handleSelectHasDaysMonthChange(event, componentHasDaysOfMonth));
        // Se añade un evento de escucha al cambio del select
        selectYearlyFrequency.addEventListener('change', this.handleSelectChangeYear);
        // Se añade todo el componente al children
        children.appendChild(componentClone);
    }

    /**
     * Función para un evento de escucha en el checkbox de si tendrá días del mes
     * @param {*} event
     * @param {*} componentHasDaysOfMonth
     */
    handleSelectHasDaysMonthChange = (event, componentHasDaysOfMonth) => {
        // se vacía el div componentHasDaysOfMonth
        componentHasDaysOfMonth.innerHTML = '';
        const value = event.target.value;
        const checked = event.target.checked;
        if (checked) {
            this.selectedHasDaysMonthOption = value;
            // Se recorre el array de días para crear el calendario con el template dayOfMonthTemplate y se añade al childrenDaysOfMonth
            for (let day = 1; day < 32; day++) {
                // se procede a crear los días del componente buscando el template frequency-day-of-month-of-year
                const dayOfMonthTemplate = document.querySelector('#frequency-day-of-month-of-year');
                const dayOfMonthClone = document.importNode(dayOfMonthTemplate.content, true);
                const labelDayOfMonth = dayOfMonthClone.querySelector(`#label-day-of-month-of-year`);
                const checkboxDayOfMonth = dayOfMonthClone.querySelector(`#checkbox-day-of-month-of-year`);
                labelDayOfMonth.id = `label-day-of-month-of-year-${day}-${this.randomIdValue}`;
                // Se añade el for
                labelDayOfMonth.setAttribute('for', `checkbox-day-of-month-of-year-${day}-${this.randomIdValue}`);
                // Se corta el string para que solo muestra la inicial en mayúsculas
                labelDayOfMonth.textContent = day;
                checkboxDayOfMonth.id = `checkbox-day-of-month-of-year-${day}-${this.randomIdValue}`;
                checkboxDayOfMonth.name = `checkbox-day-of-month-of-year-${day}-${this.randomIdValue}`;
                checkboxDayOfMonth.value = day;
                checkboxDayOfMonth.addEventListener('change', this.handleCheckboxChangeDayOfMonthOfYear);
                componentHasDaysOfMonth.appendChild(dayOfMonthClone);
            }
        } else {
            this.selectedHasDaysMonthOption = null;
        }
        if (this.selectedRadioOption) {
            this.generateText();
        }
    }

    /**
     * Función para un evento de escucha en el select cuando cambien el select del año
     * @param {*} event
     */
    handleSelectChangeYear = (event) => {
        const value = event.target.value;
        if (value) {
            this.selectedYearlyOption = value;
            if (this.selectedRadioOption) {
                this.generateText();
            }
        }
    }

    /**
     * Función para un evento de escucha en el checkbox cuando añaden o quitan un mes del año
     * @param {*} event
     * @param {*} index
     */
    handleCheckboxChangeMonthOfYear = (event, index) => {
        if (!this.checkboxMonthOfYearOption) {
            this.checkboxMonthOfYearOption = [];
        }
        const value = event.target.value;
        const checked = event.target.checked;
        if (checked) {
            this.checkboxMonthOfYearOption.push({ index, value });
        } else {
            // Se quita del this.checkboxMonthOfYearOption el elemento que se desmarcó
            this.checkboxMonthOfYearOption = this.checkboxMonthOfYearOption.filter((item) => item.index !== index);
        }
        // se ordena el this.checkboxMonthOfYearOption por index
        this.checkboxMonthOfYearOption.sort((a, b) => a.index - b.index);
        if (this.selectedRadioOption) {
            this.generateText();
        }
    }

    /**
     * Función para un evento de escucha en el checkbox cuando añaden o quitan si se verá los días del mes
     * @param {*} event
     */
    handleCheckboxChangeDayOfMonthOfYear = (event) => {
        if (this.checkboxDayOfMonthOfYearOption === null) {
            this.checkboxDayOfMonthOfYearOption = [];
        }
        const value = event.target.value;
        const checked = event.target.checked;
        if (checked) {
            this.checkboxDayOfMonthOfYearOption.push(value);
        } else {
            // Se quita del this.checkboxDayOfMonthOfYearOption el elemento que se desmarcó
            this.checkboxDayOfMonthOfYearOption = this.checkboxDayOfMonthOfYearOption.filter((item) => item !== value);
        }
        // se ordena el this.checkboxDayOfMonthOfYearOption por index
        this.checkboxDayOfMonthOfYearOption.sort((a, b) => a - b);
        if (this.selectedRadioOption) {
            this.generateText();
        }
    }

    /**
     * Función para generar el componente de final y agregarlo en el div children del container
     **/
    renderEndingComponent = () => {
        const endingComponentTemplate = document.querySelector('#frequency-ending-template');
        const componentClone = document.importNode(endingComponentTemplate.content, true);
        const children = this.container.querySelector(`#children-ending`);
        // Se añade el título a la sección id title-ending
        const titleEnding = componentClone.querySelector(`#title-ending`);
        titleEnding.textContent = this.data.ending.title;
        // Se busca el input con id radio-never y con el label label-never para ponerle el texto
        const radioNever = componentClone.querySelector(`#radio-never`);
        const labelNever = componentClone.querySelector(`#label-never`);
        labelNever.textContent = this.data.ending.never;
        // se añade el for al label
        labelNever.setAttribute('for', `${this.data.ending.never}-${this.randomIdValue}`);
        radioNever.id = `endingRadios-${this.data.ending.never}-${this.randomIdValue}`;
        radioNever.name = `endingRadios-${this.randomIdValue}`;
        // Se añade el label al label con id label-date y con el id radio-date
        const labelDate = componentClone.querySelector(`#label-date`);
        const radioDate = componentClone.querySelector(`#radio-date`);
        const inputDate = componentClone.querySelector(`#input-date`);
        labelDate.textContent = this.data.ending.on;
        // se añade el for al label
        labelDate.setAttribute('for', `${this.data.ending.on}-${this.randomIdValue}`);
        radioDate.id = `endingRadios-${this.data.ending.on}-${this.randomIdValue}`;
        radioDate.name = `endingRadios-${this.randomIdValue}`;
        inputDate.id = `date-${this.data.id}-${this.randomIdValue}`;
        inputDate.name = `date-${this.data.id}-${this.randomIdValue}`;
        inputDate.disabled = true;
        // Se añade el label al label con id label-end-repeats y con el id radio-date
        const labelAfter = componentClone.querySelector(`#label-end-repeats`);
        const textAfter = componentClone.querySelector(`#text-end-repeats`);
        const radioAfter = componentClone.querySelector(`#radio-after`);
        const selectAfter = componentClone.querySelector(`#end-select`);
        selectAfter.id = `end-select-${this.data.id}-${this.randomIdValue}`;
        selectAfter.name = `end-select-${this.data.id}-${this.randomIdValue}`;
        labelAfter.textContent = this.data.ending.after;
        // se añade el for al label
        labelAfter.setAttribute('for', `${this.data.ending.after}-${this.randomIdValue}`);
        radioAfter.id = `endingRadios-${this.data.ending.after}-${this.randomIdValue}`;
        radioAfter.name = `endingRadios-${this.randomIdValue}`;
        textAfter.textContent = this.data.ending.after_text;
        // Se añade la option por defecto del select
        this.addFirstOptionInSelect(selectAfter);
        // Se agregar las options al select creando un for de 1 a 999
        for (let i = 1; i <= 99; i++) {
            const optionElement = document.createElement('option');
            optionElement.value = i;
            optionElement.textContent = i;
            selectAfter.appendChild(optionElement);
        }
        selectAfter.disabled = true;
        // Se añade un evento de escucha al cambio de los radios
        radioNever.addEventListener('change', (event) => this.handleRadioChange(event, inputDate, selectAfter));
        radioDate.addEventListener('change', (event) => this.handleRadioChange(event, inputDate, selectAfter));
        radioAfter.addEventListener('change', (event) => this.handleRadioChange(event, inputDate, selectAfter));

        inputDate.addEventListener('change', this.handleEndingChange);
        selectAfter.addEventListener('change', this.handleEndingChange);
        // Se añade todo el componente al children
        children.appendChild(componentClone);
    }

    /**
     * Función para un evento de escucha en el checkbox cuando añaden o quitan si se añade data al ending
     * @param {*} event
     */
    handleEndingChange = (event) => {
        const value = event.target.value;
        if (value) {
            this.selectedRadioValue = value;
            if (this.selectedDailyOption || (this.selectedWeeklyOption && this.checkboxDayOfWeekOption) || (this.selectedMonthlyOption && this.checkboxDayOfMonthOption)
                || (this.selectedYearlyOption && this.checkboxMonthOfYearOption)
            ) {
                this.generateText();
            }
        }
    }

    /**
     * Función para un evento de escucha en el checkbox cuando añaden o quitan si se añade data al ending
     * @param {*} event
     */
    handleRadioChange = (value, inputDate, selectAfter) => {
        const selectedOption = value.target.value;
        this.selectedRadioOption = selectedOption;
        if (selectedOption === 'never') {
            inputDate.value = '';
            inputDate.disabled = true;
            selectAfter.value = '';
            selectAfter.disabled = true;
            this.selectedRadioValue = this.data.ending.never;
            // Check if both inputDate and selectAfter have valid values
            if (this.selectedDailyOption
                || (this.selectedWeeklyOption && this.checkboxDayOfWeekOption)
                || (this.selectedMonthlyOption && this.checkboxDayOfMonthOption)
                || (this.selectedYearlyOption && this.checkboxMonthOfYearOption)
            ) {
                this.generateText();
            }
        } else if (selectedOption === 'date') {
            inputDate.disabled = false;
            selectAfter.value = '';
            selectAfter.disabled = true;
        } else if (selectedOption === 'after') {
            selectAfter.disabled = false;
            inputDate.value = '';
            inputDate.disabled = true;
        }
    }

    /**
     * Función para obtener los días de la semana en base a un idioma
     *
     * @param {*} lang
     * @returns array
     * */
    getDaysOfWeek = (lang = 'es') => {
        const days = []
        const date = new Date('07/03/2023') // Fecha actual

        for (let i = 1; i <= 7; i++) {
            days.push(date.toLocaleDateString(lang, { weekday: 'long' }))
            date.setDate(date.getDate() + 1)
        }
        return days
    }

    /**
     * Función para obtener los meses del año en base a un idioma
     *
     * @param {*} lang
     * @returns array
     * */
    getMonthsOfYear = (lang = 'es') => {
        const months = []
        const date = new Date('01/01/2023') // Fecha actual
        for (let i = 1; i <= 12; i++) {
            months.push(date.toLocaleDateString(lang, { month: 'long' }))
            date.setMonth(i)
        }
        return months
    }

    /**
     * Función para añadir el texto al final del div children del container
     * @param {*} select
     * @returns
     * */
    generateText = () => {
        let value = `${this.data.ending.text_repeats} `;
        if (this.selectedDailyOption) {
            value += `${this.data.options[0].elements[0].label} ${this.selectedDailyOption} ${this.data.options[0].elements[0].text} `;
        } else if (this.selectedWeeklyOption && this.checkboxDayOfWeekOption) {
            // Se debe recorrer el array de this.checkboxDayOfWeekOption para pasar de {index, value } a solo sacar value separado por comas
            const days = this.checkboxDayOfWeekOption.map(objeto => objeto.value).join(', ');
            value += `${this.data.options[1].elements[0].label} ${this.selectedWeeklyOption} ${this.data.options[1].elements[0].text}`;
            value += this.checkboxDayOfWeekOption.length > 0 ? `, ${this.data.options[1].elements[1].text} ${days}. ` : ' '
        } else if (this.selectedMonthlyOption && this.checkboxDayOfMonthOption) {
            // Se debe recorrer el array de this.checkboxDayOfWeekOption para pasar de {index, value } a solo sacar value separado por comas
            value += `${this.data.options[2].elements[0].label} ${this.selectedMonthlyOption} ${this.data.options[2].elements[0].text}`;
            if (this.checkboxDayOfMonthOption.length > 0) {
                const days = this.checkboxDayOfMonthOption.join(', ');
                value += `, ${this.data.options[2].elements[1].label} ${days}. `
            }

        } else if (this.selectedYearlyOption && this.checkboxMonthOfYearOption) {
            // Se debe recorrer el array de this.checkboxMonthOfYearOption para pasar de {index, value } a solo sacar value separado por comas
            const months = this.checkboxMonthOfYearOption.map(objeto => objeto.value).join(', ');
            value += `${this.data.options[3].elements[0].label} ${this.selectedYearlyOption} ${this.data.options[3].elements[0].text}`;
            value += this.checkboxMonthOfYearOption.length > 0 ? `, ${this.data.options[3].elements[1].label} ${months} ` : ' '
            if (this.selectedHasDaysMonthOption && this.checkboxDayOfMonthOfYearOption) {
                const days = this.checkboxDayOfMonthOfYearOption.join(', ');
                value += this.checkboxDayOfMonthOfYearOption.length > 0 ? `, ${this.data.options[3].elements[3].label} ${days}. ` : ' '
            }
        }
        value += `${this.data.ending.title}: `;
        if (this.selectedRadioOption === 'never') {
            value += `${this.selectedRadioValue}`;
        } else if (this.selectedRadioOption === 'date') {
            value += `${this.data.ending.on} ${this.selectedRadioValue}`;
        }
        else {
            value += `${this.data.ending.after} ${this.selectedRadioValue} ${this.data.ending.after_text}`;
        }

        // Antes de agregar el valor del texto al div se limpia el valor del div
        if (document.getElementById(`message-details-${this.data.id}`)) {
            document.getElementById(`message-details-${this.data.id}`).textContent = '';
            document.getElementById(`message-details-${this.data.id}`).textContent = value;
        } else {
            const messageTemplate = document.querySelector(`#frequency-message-details-template`);
            const componentClone = document.importNode(messageTemplate.content, true);
            const children = componentClone.querySelector(`#message-details-${this.data.id}`);
            children.textContent = value;
            this.container.appendChild(componentClone);
        }
        this.generateJson();
    }

    /**
     * Función para añadir el json al input hidden
     * */
    generateJson = () => {
        const json = {
            frequencyId: this.frequencyId,
        }
        // Se valida si se seleccionó una opción de daily
        if (this.selectedDailyOption) {
            json.daily = {
                value: this.selectedDailyOption,
            }
        }
        // Se valida si se seleccionó una opción de weekly
        if (this.selectedWeeklyOption && this.checkboxDayOfWeekOption) {
            json.weekly = {
                value: this.selectedWeeklyOption,
                days: this.checkboxDayOfWeekOption,
            };
        }
        // Se valida si se seleccionó una opción de monthly
        if (this.selectedMonthlyOption && this.checkboxDayOfMonthOption) {
            json.monthly = {
                value: this.selectedMonthlyOption,
                days: this.checkboxDayOfMonthOption,
            };
        }
        // Se valida si se seleccionó una opción de yearly
        if (this.selectedYearlyOption && this.checkboxMonthOfYearOption) {
            json.yearly = {
                value: this.selectedYearlyOption,
                months: this.checkboxMonthOfYearOption,
            };
            // Se valida si se seleccionó una opción de days of month of year
            if (this.selectedHasDaysMonthOption && this.checkboxDayOfMonthOfYearOption) {
                json.yearly.days = this.checkboxDayOfMonthOfYearOption;
            }
        }
        // Se valida si se seleccionó una opción de ending
        if (this.selectedRadioOption) {
            json.ending = {
                option: this.selectedRadioOption,
                value: this.selectedRadioValue,
            }
        }
        // Por último se añade el json al input hidden que esta en this.data.hidden
        const inputHidden = document.getElementById(this.data.hidden);
        inputHidden.value = JSON.stringify(json);
    }

    /**
     * Función para comprobar si tiene un objeto json con data
     */
    checkJsonData = () => {
        if (this.data.hiddenValue) {
            const json = JSON.parse(this.data.hiddenValue)
            // Si tiene el valor de frequencyId seleccionado se selecciona ya la opción de frecuencia.
            if (json.frequencyId) {
                // Se selecciona el select de frecuencia
                const selectFrequency = this.container.querySelector(`#${this.data.name}-${this.data.id}-${this.randomIdValue}`);
                selectFrequency.value = json.frequencyId;
                this.frequencyId = json.frequencyId;
                // Para continuar con la lógica se debe seleccionar el select de frecuencia
                this.handleFirstSelectChangeLocal({ target: { value: json.frequencyId } });
                // Se comprueba que tenga el valor de daily
                if (json.daily) {
                    // Se selecciona el radio de daily
                    const selectDaily = this.container.querySelector(`#${this.data.options[0].name}-${this.randomIdValue}`);
                    selectDaily.value = json.daily.value;
                    this.selectedDailyOption = json.daily.value;
                    this.handleSelectDailyChange({ target: { value: json.daily.value } });
                }
                // Se comprueba que tenga el valor de weekly
                if (json.weekly) {
                    // Se selecciona el radio de weekly
                    const selectWeekly = this.container.querySelector(`#${this.data.options[1].name}-${this.randomIdValue}`);
                    selectWeekly.value = json.weekly.value;
                    this.selectedWeeklyOption = json.weekly.value;
                    this.handleSelectChangeWeek({ target: { value: json.weekly.value } });
                    // Se recorre el array de days para seleccionar los checkbox
                    json.weekly.days.forEach((day) => {
                        const checkboxDayOfWeek = this.container.querySelector(`#${day.value}-${this.randomIdValue}`);
                        checkboxDayOfWeek.checked = true;
                        this.handleCheckboxChangeWeek({ target: { value: day.value } }, day.index);
                    });
                }
                // Se comprueba que tenga el valor de monthly
                if (json.monthly) {
                    // Se selecciona el select de monthly
                    const selectMonthly = this.container.querySelector(`#${this.data.options[2].name}-${this.randomIdValue}`);
                    selectMonthly.value = json.monthly.value;
                    this.selectedMonthlyOption = json.monthly.value;
                    this.handleSelectChangeMonth({ target: { value: json.monthly.value } });
                    // Se recorre el array de days para seleccionar los checkbox
                    json.monthly.days.forEach((day) => {
                        const checkboxDayOfMonth = this.container.querySelector(`#checkbox-day-of-month-${day}-${this.randomIdValue}`);
                        checkboxDayOfMonth.checked = true;
                        this.handleCheckboxChangeMonth({ target: { value: day, checked: true } });
                    });
                }
                // Se comprueba que tenga el valor de yearly
                if (json.yearly) {
                    // Se selecciona el select de yearly
                    const selectYearly = this.container.querySelector(`#${this.data.options[3].name}-${this.randomIdValue}`);
                    selectYearly.value = json.yearly.value;
                    this.selectedYearlyOption = json.yearly.value;
                    this.handleSelectChangeYear({ target: { value: json.yearly.value } });
                    // Se recorre el array de months para seleccionar los checkbox
                    json.yearly.months.forEach((month) => {
                        if (!this.checkboxMonthOfYearOption) {
                            this.checkboxMonthOfYearOption = [];
                        }
                        this.checkboxMonthOfYearOption.push(month);
                        const checkboxMonthOfYear = this.container.querySelector(`#checkbox-month-of-year-${month.value}-${this.randomIdValue}`);
                        checkboxMonthOfYear.checked = true;
                        this.handleCheckboxChangeMonthOfYear({ target: { value: month.value } }, month);
                    });
                    // Se comprueba que tenga el valor de days
                    if (json.yearly.days) {
                        // Se selecciona el checkbox de days
                        const checkboxHasDaysMonth = this.container.querySelector(`#checkbox-has-days-of-month-of-year-${this.randomIdValue}`);
                        checkboxHasDaysMonth.checked = true;
                        this.selectedHasDaysMonthOption = true;
                        this.handleSelectHasDaysMonthChange({ target: { value: true, checked: true } }, this.container.querySelector(`#days-of-month-of-year-${this.randomIdValue}`));
                        // Se recorre el array de days para seleccionar los checkbox
                        json.yearly.days.forEach((day) => {
                            const checkboxDayOfMonth = this.container.querySelector(`#checkbox-day-of-month-of-year-${day}-${this.randomIdValue}`);
                            checkboxDayOfMonth.checked = true;
                            this.handleCheckboxChangeDayOfMonthOfYear({ target: { value: day, checked: true } });
                        });
                    }
                }

                // Se carga los datos de ending comprobando si los tiene
                if (json.ending) {
                    // Se debe comprobar si tiene el valor de never, date o after
                    if (json.ending.option === 'never') {
                        // Se selecciona el radio de ending
                        const radioNever = this.container.querySelector(`#endingRadios-${this.data.ending.never}-${this.randomIdValue}`);
                        radioNever.checked = true;
                    } else if (json.ending.option === 'date') {
                        // Se selecciona el radio de ending
                        const radioDate = this.container.querySelector(`#endingRadios-${this.data.ending.on}-${this.randomIdValue}`);
                        radioDate.checked = true;
                        // Se busca el input para añadirle el valor
                        const inputDate = this.container.querySelector(`#date-${this.data.id}-${this.randomIdValue}`);
                        inputDate.value = json.ending.value;
                        inputDate.disabled = false;
                    } else if (json.ending.option === 'after') {
                        // Se selecciona el radio de ending
                        const radioAfter = this.container.querySelector(`#endingRadios-${this.data.ending.after}-${this.randomIdValue}`);
                        radioAfter.checked = true;
                        // Se busca el select para añadirle el valor
                        const selectAfter = this.container.querySelector(`#end-select-${this.data.id}-${this.randomIdValue}`);
                        selectAfter.value = json.ending.value;
                        selectAfter.disabled = false;
                    }
                    this.selectedRadioOption = json.ending.option;
                    this.selectedRadioValue = json.ending.value;
                }
            }
        }
    }
}
// Se crea una instancia global de la clase asignada al window
window.FrequencyComponent = FrequencyComponent;

// Usage examples:
// const frequencyComponentWithData = new FrequencyComponent('frequency-app',data);
// frequencyComponentWithData.init();

// Usage examples:
// const frequencyComponentWithoutData = new FrequencyComponent('frequency-app');
// frequencyComponentWithData.init();

// Crear función después que la página esta cargada y buscar todos los elementos con component="frequency-app" y crear una instancia del mismo.
document.addEventListener('DOMContentLoaded', () => {
    const frequencyComponents = document.querySelectorAll('[component="frequency-component"]');
    frequencyComponents.forEach(element => {
        const id = element.getAttribute('id');
        const frequencyComponent = new FrequencyComponent(id);
        frequencyComponent.init();
    });
});


