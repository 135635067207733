import $ from 'jquery';
window.$ = window.jQuery = $;

import moment from 'moment';
window.moment = moment;
// Importa el archivo nucleus.js que contiene todos los componentes de Nucleus
import $nucleus from './nucleus/nucleus';
window.nucleus = $nucleus;

// require('./bootstrap.js');
// require('./general.js');

// COMPONENTS
require('./components/loader.js');
require('./components/bootstrap-form-validate.js');
require('./components/opt.js');
require('./components/modal-menu.js');
require('./components/modal-delete.js');
require('./ideasfarm/light-sheet.js');
require('./ideasfarm/hover-table-action-default.js');
// require('./components/mtm-components/mtm.js');
require('./components/frequency.js');
// require('./components/ag-grid.js');
// require('./libraries/sticksy.js');
// require('./components/sticky-kit.js');
// require('./general.js');
// require('./components/ag-grid.js');

// require('./components/dropdown.js');
// require('./components/olw-carousel2.js');
// require('./components/overlay-menu.js');
// require('./components/mapplace.js');
// require('./components/smooth-scroll.js');
// require('./components/edittable.js');
// require('./components/collapse.js');
// require('./components/modal.js');

// require('./login.js');

//require('perfect-scrollbar');
// require('bootstrap');
// require('./components/data-tables.js');



let Jquery = require('jquery');
window.jQuery = jQuery;

function copyToClipboard(element) {
    var $temp = $("<input>");

    $("body").append($temp);

    $temp.val($(element).text()).select();

    document.execCommand("copy");

    $temp.remove();
}

// importa la librería de forms
let iforms = require('./libraries/forms.js');
// hace accesible la librería de forms fuera del paquete
window.iforms = iforms;

// importa la librería gráficos echarts
import * as echarts from 'echarts';
window.echarts = echarts;
