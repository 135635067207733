class BaseComponent
{
    triggerSelector = null;

    constructor() {
        // Constructor lógica aquí
    }

    selectElements($selector)
    {
        // Si se ha pasado un selector, se inicializa el botón manualmente...
        if ($selector) {
            // ... y se guarda el botón en la propiedad button
            this.element = document.querySelector($selector);

            // Verifica que el botón exista...
            if (!this.element) {
                // ... y muestra un error en la consola si no existe...
                console.error(`element with selector "${selector}" not found.`);
                // ... y sale de la función
                return;
            }
        } else {
            // ... si no se ha pasado un selector, se inicializan todos los botones
            this.elements = document.querySelectorAll('[data-nc-trigger="' + this.triggerSelector + '"]');
        }
    }
}

export default BaseComponent;
