// Importa todos los componentes de Nucleus aquí
import CopyToClipboard from './CopyToClipboard/init';
import FetchClient from './FetchClient/init';
import MercureClient from './MercureClient/init';
import SwapContent from './SwapContent/init';
import ManyToManySelector from './mtm-components/init';

// Exporta los componentes como parte del objeto 'nucleus'
export default {
    copyToClipboard: CopyToClipboard,
    swapContent: SwapContent,
    manyToManySelector: ManyToManySelector,
    fetchClient: FetchClient,
    mercureClient: MercureClient,
    // Agrega aquí otros módulos de Nucleus que quieras exponer
};
