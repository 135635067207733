/**
 * Description: funciones helpers
 *
 * Importa todos los ficheros de la carpeta helpers y los exporta en un solo objeto
 *
 * ES6 Module
 *
 * @category   Nucleus
 * @package    Helpers
 * @author     Miguel Valencia @mavo <nova_mig@hotmail.com>
 */

// Importa las funciones de cada archivo
const $helpersDebug = require('./helpersDebug');

module.exports = {
    ...$helpersDebug
};