/**
 * Description: Mixins generales para los componentes
 *
 * Contiene los métodos que se pueden utilizar en todas las clases de los componentes
 * de Nucleus.
 *
 * ES6 Module
 *
 * @category   Nucleus
 * @package    Mixins
 * @author     Miguel Valencia @mavo <nova_mig@hotmail.com>
 */
const nuc          = require('../helpers/helpers');  // Importa todas las funciones helpers
const $_changeCase = require('change-case');         // Importa la biblioteca change-case

const $_generalMixins = {
    /**
     * Busca un parámetro de configuración en las propiedades data del elemento padre
     * si no se encuentra, busca en el objeto de configuración del componente
     * siempre la propiedad del elemento padre tiene prioridad sobre la del componente
     *
     * @param {HTMLElement} $element El elemento HTML al que se le quiere buscar el parámetro de configuración.
     * @param {string} $param El nombre del parámetro de configuración que se quiere buscar.
     * @returns {string} El valor del parámetro de configuración encontrado.
     */
    getConfigParam($element, $param) {
        // Convierte el nombre del parámetro a camelCase (o el case que necesites)
        let $configParamName = $_changeCase.camelCase($param);
        // Busca el parámetro de configuración en las propiedades data del elemento padre
        let $configParamValue = $element.dataset[$_changeCase.camelCase("nc-" + $configParamName)];

        // Verifica si se encontró el parámetro de configuración en las propiedades data del elemento padre
        if ($configParamValue != undefined) {
            // Retorna el valor del parámetro de configuración
            return $configParamValue;
        } else {
            // ... si se encuentra, retorna el null
            $configParamValue = this.config[$configParamName];
            // ... si no se encuentra, busca en el objeto de configuración del componente
            if ($configParamValue != undefined) {
                // Retorna el valor del parámetro de configuración
                return $configParamValue;
            }
        }

        // Muestra un mensaje de error en la consola
        nuc.de(`No se encontró el parámetro de configuración "${$configParamName}" en el elemento #${$element.id}`);
        // Retorna null
        return null;
    }
};

module.exports = $_generalMixins;
