import $ from 'jquery';
import moment from 'moment';

init()

function init() {
    // Selecciona el botón de edición en la tabla y asigna un evento de clic a ese botón
    $(document).on('click', 'a[data-trigger="update-row"]', function() {
        // Obtiene la URL del recurso que se va a editar a través del atributo de datos del botón
        let url = $(this).data('row');
        let updateUrl = $(this).data('url');

        // Llama a la función "loadDataForm" para cargar los datos del formulario desde
        // la URL del recurso que se va a editar La función toma tres argumentos: el
        // identificador del formulario ("formId"), la URL del recurso que se va a editar
        // ("url"), y una función de devolución de llamada opcional que se ejecuta cuando
        // se completa la carga de datos en el formulario
        loadDataForm('#formUpdate', url, updateUrl, function() {
            // Oculta el spinner y muestra el formulario
            console.log('Carga de datos completada');
        });
    });

    $('#formUpdate').on('hidden.bs.modal', function() {
        // Resetear formulario a estado inicial
        let formContainer = $('#formUpdate');
        let form = formContainer.find('.form');

        // Ocultar formulario
        form.css('opacity', 0);
        // Eliminar estilo "display:none" del spinner-inner
        formContainer.find('.spinner-inner').css('display', '');
    });
}

function loadDataForm(formId, url, updateUrl, callback) {
    // Obtiene el contenedor del formulario
    let formContainer = $(formId);
    // Obtiene el formulario
    let form = formContainer.find('.form');
    // Obtiene el spinner
    let spinner = formContainer.find('.spinner-inner');

    form.attr('action', updateUrl);
    // Vacía el formulario utilizando el método reset()
    form[0].reset();

    callApi(url, formContainer, function(data) {
        // Oculta el spinner con un efecto de fadeout
        spinner.fadeOut(150, function() {
            // Obtiene los inputs del formulario
            let inputs = form.find('.form-control');
            console.log(inputs);
            // Recorre los inputs del formulario
            $.each(inputs, function(key, input) {
                // Convierte el input a un objeto jquery
                input = $(input);
                // Verifica si el elemento es un input
                if (getInputType(input) == 'INPUT') {
                    checkTypeInput(input, data);
                // Verifica si el elemento es un select
                } else if (getInputType(input) == 'SELECT') {
                    // Carga el valor obtenido del api al input
                    setInputValue(input, data);
                // Verifica si el elemento es un textarea
                } else if (getInputType(input) == 'TEXTAREA') {
                    // Carga el valor obtenido del api al input
                    setInputValue(input, data);
                } else {
                    // Código a ejecutar si el elemento no es ninguno de los anteriores
                }
            });
            // Muestra el formulario con un efecto de fadein
            form.animate({opacity: '1'}, 300, function() {
                // Ejecuta la función de callback si se proporcionó
                if (callback) {
                    callback();
                }
            });
        });
    });
}
/**
 *
 * Hace una llamada a un api GET y retorna l
 *
 */
function callApi(url, formContainer, callback) {
    $.ajax({
        url: url,
        method: 'GET',
        success: function(data, textStatus, jqXHR) {
            // obtiene el tipo de contenido de la respuesta
            let contentType = jqXHR.getResponseHeader('Content-Type');

            // Verifica si el tipo de contenido es JSON
            if (contentType.includes('application/json')) {
                // Ejecuta la función de callback con los datos obtenidos del api
                callback(data.result);
            } else {
                // Muestra un mensaje de alerta si la respuesta no es JSON
                alert('Respuesta ' + url + ' no es contenido JSON');
                // Oculta el modal
                formContainer.modal('hide');
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.error(textStatus + ': ' + errorThrown);
        }
    });
}

/**
 * Obtiene el tipo de input
 *
 * @param {object} input
 *
 * @returns {string}
 */
function getInputType(input) {
    // Obtiene la etiqueta del input
    var input = $(input);

    // Verifica si es un input de tipo checkbox o radio
    if (input.is('input') ||
        input.is('textarea') ||
        input.is('select') ||
        input.is('button') ||
        input.is('datalist')
    ) {
        // Si es un input de tipo checkbox o radio
        return input[0].tagName;
    } else {
        return false;
    }
}

function checkTypeInput(input, data) {
    let type = input.attr('type');
    console.log(type);
    switch (type) {
        case 'text':
            console.log('text');
            // Asigna el valor obtenido del api al input
            setInputValue(input, data)
            break;
        case 'password':
            // Asigna el valor obtenido del api al input
            setInputValue(input, data)
            break;
        case 'email':
            // Realizar acciones para inputs de tipo email
            break;
        case 'tel':
            // Realizar acciones para inputs de tipo tel
            break;
        case 'url':
            // Realizar acciones para inputs de tipo url
            break;
        case 'search':
            // Asigna el valor obtenido del api al input
            setInputValue(input, data)
            break;
        case 'number':
            // Realizar acciones para inputs de tipo number
            break;
        case 'range':
            // Realizar acciones para inputs de tipo range
            break;
        case 'date':
            // Asigna el valor obtenido del api al input
            setInputDateValue(input, data)
            break;
        case 'time':
            // Asigna el valor obtenido del api al input
            setInputTimeValue(input, data)
            break;
        case 'datetime-local':
            // Realizar acciones para inputs de tipo datetime-local
            break;
        case 'month':
            // Realizar acciones para inputs de tipo month
            break;
        case 'week':
            // Realizar acciones para inputs de tipo week
            break;
        case 'checkbox':
            // Realizar acciones para inputs de tipo checkbox
            break;
        case 'radio':
            console.log('radio');
            // Realizar acciones para inputs de tipo radio
            break;
        case 'file':
            // Realizar acciones para inputs de tipo file
            break;
        case 'button':
            // Realizar acciones para inputs de tipo button
            break;
        case 'submit':
            // Realizar acciones para inputs de tipo submit
            break;
        case 'reset':
            // Realizar acciones para inputs de tipo reset
            break;
        case 'hidden':
            // Asigna el valor obtenido del api al input
            setInputValue(input, data)
            break;
        default:
            // Realizar acciones para otros tipos de campos
            break;
    }
}

function setInputValue(input, data) {
    // Obtiene el nombre del campo
    var name = $(input).attr('name');
    console.log(name, data);
    // Obtiene el valor del campo
    var value = data[name];
    // Asigna el valor al campo
    $(input).val(value);
}

function setInputDateValue(input, data) {
    // Obtiene el nombre del campo
    var name = $(input).attr('name');
    // Obtiene el valor del campo
    var value = data[name];
    // Asigna el valor al campo
    $(input).val(
        // Convierte el valor al formato 'YYYY-MM-DD'
        moment(value).format('YYYY-MM-DD')
    );
}

/**
 *
 * @param {object} input Objeto jQuery del campo
 * @param {object} data Datos
 */
function setInputTimeValue(input, data) {
    // Obtiene el nombre del campo
    var name = $(input).attr('name');
    // Obtiene el valor del campo
    var value = data[name];
    // Crea una fecha a partir de una cadena de hora en formato 'HH:mm'
    var hora = moment(value, 'HH:mm');
    // Formatea la hora en formato 'HH:mm'
    var horaFormateada = hora.format('HH:mm');
    // Asigna el valor al campo
    $(input).val(horaFormateada);
}

/**
 * Exporta el modulo
 */
module.exports = {
    loadDataForm: loadDataForm,
};