// Importar la clase FetchClient
import FetchClient from './FetchClient';

// agrega un listener al contenido cuando se carga el DOM...
document.addEventListener('DOMContentLoaded', function () {
    // ... e inicializa los botones
    const fetchClientInstance = new FetchClient();
});

export default FetchClient;
