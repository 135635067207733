import $ from 'jquery';

$('.options-button').on('click', function() {
    // Obtenemos el objeto JSON de data-route-vars
    const routeVars = $(this).data('route-vars');

    // Clonamos el modal para no modificar el original
    const clonedModal = $('#optionsModal').clone();

    const animalName = $(this).data('animal-data');

    // Reemplazamos las variables en las rutas de las opciones del modal
    clonedModal.find('a.link-item').each(function() {
        let href = $(this).attr('href');

        // Verifica si el atributo href existe y contiene una variable
        if (href && /\$[a-zA-Z]+(:[a-zA-Z]+)?/.test(href)) {

            for (const key in routeVars) {
                // Reemplaza las variables tanto con tipo como sin tipo de dato
                const regex = new RegExp(`\\$${key}(:[a-zA-Z]+)?`, 'g');
                href = href.replace(regex, routeVars[key]);
                console.warn(href, routeVars);
            }

            $(this).attr('href', href);
        }
    });
    // Selecciona el elemento <p> con el id "myParagraph"
    let paragraphElement = $("#myParagraph");
    // Establece el texto del elemento <p>
    clonedModal.find("#modal-menu-animal-name").html(animalName);

    // Reemplazamos el modal original con el clon modificado y lo abrimos
    $('#optionsModal').replaceWith(clonedModal);
    let myOptions = new bootstrap.Modal(document.getElementById('optionsModal'))
    myOptions.show();
});
